import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AnimeCardComponent.css';

const AnimeCardComponent = ({ anime, isInteractive = false }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const cardRef = useRef();

    const handleMouseEnter = () => {
        if (!isInteractive) return;
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isInteractive) return;
        setIsHovered(false);
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleWatchButtonClick = () => {
        navigate(`/anime/${anime.id}`);
    };

    const description = () => {
        if (anime.description === undefined) return;
        if (anime.description === null) return;
        return anime.description.length > 80
            ? anime.description.substring(0, 80) + "..."
            : anime.description;
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <a
            className="anime-card"
            href={`/anime/${anime.id}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={cardRef}
        >
            {isVisible && (
                <img
                    src={`https://api.animix.lol/anime/getThumb/${anime.id}_thumb.png`}
                    alt={anime.name}
                    className={`anime-thumbnail ${isHovered ? 'hovered' : ''} ${imageLoaded ? 'loaded' : ''}`}
                    onLoad={handleImageLoad}
                />
            )}

            {isInteractive ? (
                <div className="anime-info">
                    <div className='anime-text-container'>
                        <h3 className="anime-title-card">{anime.name}</h3>
                        <p className='anime-description'>{description()}</p>
                    </div>
                    <button className='button-sec watch-button' onClick={handleWatchButtonClick}>
                        Смотреть
                    </button>
                </div>
            ) : null}

            <h4 className={`rating ${anime.rating >= 7.5 ? anime.rating >= 9 ? "premium" : "good" : ""}`}>★ {anime.rating}</h4>
        </a>
    );
};

export default AnimeCardComponent;
