import React, { useEffect, useRef, useState } from 'react';
import './../../App.css';
import './HomePage.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import homeVideo from '../../assets/home.mp4';
import AnimeCardComponent from '../../components/animecard/AnimeCardComponent';
import ApiService from '../../services/ApiService';
import WatchCardComponent from '../../components/watchcard/WatchCardComponent';
import telegram from '../../assets/telegram.png';
import YandexAdComponent from '../../components/YandexAdComponent'; // Import the new component
import PlayIcon from '../../assets/ic_play.svg';
import mindIcon from '../../assets/ic_mind.svg';

const HomePage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [watchList, setWatchList] = useState([]);
    const [recommendation, setRecommendation] = useState(null);
    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState(null);
    const [hideBanner, setHideBanner] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state
    const videoRef = useRef(null);

    var isRecsLoad = false;

    useEffect(() => {
        const fetchPublic = async () => {
            try {
                const data = await ApiService.getPublicAnimeList(0);
                if (data.type === "success") {
                    setAnimeList(data.data);
                } else {
                    console.error('Error fetching anime list:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const catData = await ApiService.getPublicCategories();
                if (catData.type === "success") {
                    setCategories(catData.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const fetchLastWatch = async () => {
            try {
                const watchData = await ApiService.getLastWatch();
                if (watchData.type === "success") {
                    setWatchList(watchData.data);
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchRecs = async () => {
            try {
                if (recommendation == null && !isRecsLoad) {
                    isRecsLoad = true;
                    const recsArray = await ApiService.getRecs();


                    if (recsArray.type === "success" && recsArray.data.length > 0) {
                        const randomRec = recsArray.data[Math.floor(Math.random() * recsArray.data.length)];
                        setRecommendation(randomRec);
                        console.log(JSON.stringify(randomRec))

                        const data = await ApiService.getSeries(randomRec.id);

                        if (data.data["1"].length > 0) {
                            const animeData = await ApiService.getWatchSession(data.data["1"][0].privateId);

                            if (animeData.type === "success") {

                                setUrl("https://cache.animix.lol/content/watch?session=" + animeData.data);

                                // setHasFirst(true);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const loadData = async () => {
            await fetchLastWatch();
            await fetchPublic();
            setLoading(false); // Set loading to false after data is fetched

            await fetchRecs();
        };

        document.title = 'animix - Главная';
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        loadData();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {
        if (videoRef.current != null) {
            if (videoRef.current.currentTime < 50) {
                videoRef.current.currentTime = 50;
            }
        }
    };

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="landing-content">
                {url == null ?
                    <video key={homeVideo} className="backgroundVideo" autoPlay loop muted>
                        <source src={homeVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> :
                    <video key={url} ref={videoRef} onPlay={handleLoad} className="backgroundVideo" autoPlay loop muted>
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }

                <div className="landing-text">
                    <div className="text">
                        {recommendation == null ? "" :
                            <div className="neuro-recommendation">
                                <img src={mindIcon} alt="telegram" className="neuro-recommendation-icon" />

                                <span className="neuro-recommendation-text">Нейронная рекомендация</span>
                            </div>}
                        <h1 className="title">{recommendation == null ? "Звёздное дитя" : recommendation.name}</h1>
                        <p>{recommendation ? (recommendation.description.length > 100 ? `${recommendation.description.substring(0, 100)}...` : recommendation.description) : "Потрясающий сюжет и великолепная рисовка"}</p>

                    </div>
                    <p style={{ opacity: 0.8 }}>{recommendation == null ? "" : "Подобрано нейронной сетью на основе Ваших положительных оценок"}</p>
                    <a href={`/anime/${recommendation == null ? "705" : recommendation.id}`}
                        className='actionButton playButton'> <img src={PlayIcon} alt="Play Icon" className="playIcon" />Смотреть</a>
                </div>
            </div>

            <div className='content animeblock'>
                {loading ? (
                    <div className="loader"></div> // Show loader while loading
                ) : (
                    <>
                        {watchList.length > 0 && (
                            <div>
                                <h1>Продолжить просмотр</h1>
                                <div className="watch-container">
                                    {watchList.map((watchSnippet) => (
                                        <WatchCardComponent key={watchSnippet.id} watchSnippet={watchSnippet}
                                            isInteractive={true} />
                                    ))}
                                </div>
                            </div>
                        )}
                        {localStorage.getItem("subscribed") == true ? "" :
                            <YandexAdComponent blockId={localStorage.getItem("subscribed") ? `` : `R-A-8131234-1`} />}

                        {categories.map((category) => (
                            <a className="container-wrapper" key={category.id} href={`/category/${category.id}`}>
                                <h1 className='anime-title'>{category.name}</h1>
                                <div className="anime-line-container-padding">
                                    <div className="anime-line-container">
                                        {category.animeList.map((anime) => (
                                            <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                                        ))}
                                    </div>
                                    <a href={`/category/${category.id}`} className="view-all-button">Посмотреть все</a>

                                </div>

                            </a>
                        ))}
                        {localStorage.getItem("subscribed") == true ? "" : <YandexAdComponent blockId="R-A-8131234-2" />}

                        <div className="container-wrapper">
                            <h1>Недавно обновлено</h1>
                            <div className="anime-line-container-padding">
                                <div className="anime-line-container">
                                    {animeList.map((anime) => (
                                        <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                                    ))}
                                </div>
                                <a href='/all' className="view-all-button">Посмотреть все</a>
                            </div>

                        </div>
                    </>
                )}
            </div>

            {(localStorage.getItem("telegram_action3") == null && !hideBanner) && (
                <div className='telegram-banner'>
                    <img src={telegram} alt="telegram" />
                    <h3>Привет, будем на связи?</h3>
                    <p>Наш тг канал с эксклюзивным контентом!</p>
                    <a className='actionButton' href='https://t.me/animixlib'>Подписаться</a>
                    <a className='button-sec' onClick={() => {
                        localStorage.setItem("telegram_action3", "yes");
                        setHideBanner(true);
                    }}>Скрыть</a>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default HomePage;

