import React, { useEffect, useState } from 'react';
import './ProfilePic.css'; // CSS file for styling
import dura from './../../assets/dura.png';
import placeholder from './../../assets/placeholder.png';
import ApiService from '../../services/ApiService';

const frames = await ApiService.getFrames();

const ProfilePic = ({ isBig, account }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [frame, setFrame] = useState({});
    const [profileImageUrl, setProfileImageUrl] = useState(null);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
               
             

                var selectedFrameId = localStorage.getItem("selectedPicFrame")
                if (account != null) {
                    if(account.banned) return;
                    selectedFrameId = account.selectedPicFrame;
                }
              
                // Loop through the frames data
                frames.forEach(frame => {
                    if (frame.id == selectedFrameId) {
                        setFrame(frame);

                    }

                });
            } catch (error) {
                console.error('Error fetching frames:', error);
            }

            try {
                var profileImageUrl = `https://api.animix.lol/account/getProfileData/${localStorage.getItem("profileId")}_profile_pic.png`;
                if (account != null) { profileImageUrl = `https://api.animix.lol/account/getProfileData/${account.id}_profile_pic.png`; }


              
                setProfileImageUrl(profileImageUrl);

            } catch (error) {
                console.error('Error fetching profile image:', error);
            }
        };

        fetchData();

        return () => {
            // Cleanup function if needed
        };
    }, []);

    const handleImageError = () => {
        setProfileImageUrl(null);
       // setImageLoaded(true);
    }

    return (
        <div className="profile-pic-container">
            {(profileImageUrl != null) ? (
                <img
                    className={`profile-pic ${imageLoaded ? 'loaded' : ''} ${isBig ? 'big' : ''}`}
                    src={profileImageUrl}
                    alt="Profile"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                />
            ) : (
                <img
                    className={`profile-pic loaded placeholder ${isBig ? 'big' : ''}`}
                    src={dura}
                    alt="Placeholder"
                />
            )}
            {frame.url && <img className={`overlay ${isBig ? 'big' : ''}`} src={frame.url} alt="Frame" />}
        </div>
    );
};

export default ProfilePic;