import React, { useState, useEffect } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import logo from './../assets/logo_150.svg'; // Import logo.svg from the assets folder
import Header from './../components/Header';
import Footer from '../components/Footer';

const NotFoundPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };
        document.title = "404 animix";
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                <div className="">
                    <h1>Тут ничего нет</h1>
                    <p>
                     Скорее всего, Вы попали на эту страницу случайно. Пока что здесь пусто, мяу
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NotFoundPage;
