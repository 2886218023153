import React, { useState, useEffect } from 'react';
import ApiService from '../services/ApiService';

const CategoryManagementPage = () => {
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [categoryAvailable, setCategoryAvailable] = useState(true);
    const [categoryPriority, setCategoryPriority] = useState(1);
    const [categoryAnimeIds, setCategoryAnimeIds] = useState([]);
    const [selectedId, setSelectedId] = useState(-1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const categoriesData = await ApiService.getCategories();
            setCategories(categoriesData.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching categories:', error.message);
        }
    };


    const handleEditCategory = async () => {
        try {
            const category = {
                id: selectedId,
                name: categoryName,
                available: categoryAvailable,
                priority: categoryPriority,
                animeIds: categoryAnimeIds
            };
            await ApiService.setCategory(category);
            // Clear the input fields and fetch updated categories

        } catch (error) {
            console.error('Error adding category:', error.message);
        }
    };
    const handleAddEmptyCategory = async () => {
        try {
            const category = {
                name: categoryName,
                available: categoryAvailable,
                priority: categoryPriority,
                animeIds: categoryAnimeIds
            };
            await ApiService.setCategory(category);
            // Clear the input fields and fetch updated categories

        } catch (error) {
            console.error('Error adding category:', error.message);
        }
    };

    const handleLoadCategory = (category) => {
        // Handle edit functionality here
        setSelectedId(category.id);
        setCategoryName(category.name);
        setCategoryAvailable(category.available);
        setCategoryPriority(category.priority);
        setCategoryAnimeIds(category.animeIds);

    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Настройка категорий</h1>
            <div>
                <h2>Категория {selectedId}</h2>
                <input
                    type="text"
                    placeholder="Category Name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />



            <div>
            <input
                    type="checkbox"
                    id="editSeries"
                    name="editSeries"
                    checked={categoryAvailable}
                    onChange={(e) => setCategoryAvailable(e.target.checked)}
                />
                <label className='label-switch' htmlFor='editSeries'>Доступна</label>

            </div>
             
                <label>
                    Приоритет:
                    <input
                        type="number"
                        value={categoryPriority}
                        onChange={(e) => setCategoryPriority(parseInt(e.target.value))}
                    />
                </label>
                <label>
                    Anime IDs (через запятую):
                    <input
                        type="text"
                        value={categoryAnimeIds.join(',')}
                        onChange={(e) => setCategoryAnimeIds(e.target.value.split(','))}
                    />
                </label>
                <button onClick={handleEditCategory}>Сохранить</button>
                <button onClick={handleAddEmptyCategory}>Создать пустую</button>
            </div>
            <div>
                <h2>Категории (обновить страничку для обновления)</h2>
                <ul>
                    {categories.map((category) => (
                        <li key={category.id}>
                            <p>
                                {category.name} ({category.id}) -{category.available ? 'Доступна' : 'Недоступна'} - Приоритет: {category.priority} - Аниме IDs: {category.animeIds.join(',')}
                            </p>
                            <button onClick={() => handleLoadCategory(category)}>Edit</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CategoryManagementPage;
