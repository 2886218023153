import React from 'react';
import './Footer.css';
import telegram from "../assets/telegramIcon.svg"
import server from "../assets/serverIcon.svg"
import support from "../assets/supportIcon.svg"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row flex-grow">
          <div className="col-md-6 col-sm-12 text-center">
            <p>animix 2024. Применяются рекомендательные технологии</p>
            <a className='footerButton' href='https://t.me/animixlib'> 
            <img src={telegram}/> Мы в Telegram</a>
            <a className='footerButton' href='https://t.me/animixlib_bot'>
            <img src={support}/> Поддержка</a>
            <a className='footerButton' href='https://status.animix.lol'>
            <img src={server}/> Статус серверов</a>
          </div>
          <ul className="footer-links list-unstyled">
            <li><a href="/about">О нас</a></li>
            <li><a href="/community">Правила сообщества</a></li>
            <li><a href="/legal">Правообладателям</a></li>
            <li><a href="/privacy">Политика конфиденциальности</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
