import React from 'react';
import ProfilePic from '../../components/profilepic/ProfilePic';


const FriendsPopup = ({ friends, closePopup }) => {
    return (
        <div className="fr-popup-container">
            <div className="fr-popup">
                <h2>Список друзей</h2>
                {friends.length > 0 ? (
                    <div className="fr-friends-list">
                        {friends.map(friend => (
                            <a key={friend.id} href={`/profile/${friend.nickname}`}>
                                <div className="fr-friend-item">
                                    <ProfilePic isBig={false} account={friend} />
                                    <p>{friend.nickname}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                ) : (
                    <p className="no-friends-message">Друзей нет.</p>
                )}
                <button className="button-sec" onClick={closePopup}>Закрыть</button>
            </div>
        </div>
    );
};

export default FriendsPopup;
