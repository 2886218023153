import ApiService from './../../services/ApiService';
import React, { useState } from 'react';
import './UploadComponent.css';
import './../../App.css';
import tasty from './../../assets/tasty.jpg';

const UploadAnimeComponent = () => {
  const [file, setFile] = useState(null);
  const [animeId, setAnimeId] = useState('');
  const [seriesNum, setSeriesNum] = useState('');
  const [seasonNum, setSeasonNum] = useState('');
  const [key, setKey] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const [priority, setPriority] = useState(1);
  const [successMessage, setSuccessMessage] = useState('');
  const [notify, setNotify] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage('');
    setLoading(true);

    try {
    
      const response = await ApiService.uploadAnime(file, animeId, seriesNum, seasonNum, url, key, priority, notify);
      if (response.type === 'success') {
        setSuccessMessage(`Видео загружено: id ${response.data.id}, серия ${response.data.seriesNum}`);
      } else {
        setError(`Ошибка загрузки: ${JSON.stringify(response.data)}`);
      }
    } catch (error) {
      setError('Ошибка загрузки');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='upload-content'>
      <img className="image-tasty" src={tasty} alt="Tasty" />
      <h2>Загрузка аниме</h2>
      <form onSubmit={handleSubmit} className="upload-form">
        <div className="input-group">
          <label htmlFor="file">Выбор файла: {file ? file.name : ""}</label>
          <label htmlFor="background-upload" className="upload-label">
            Выбрать файл
            <input id="background-upload" type="file" accept="*" onChange={handleFileChange} />
          </label>
        </div>
        <div className="input-group">
          <label htmlFor="animeId">Anime ID:</label>
          <input
            type="text"
            id="animeId"
            value={animeId}
            onChange={(e) => setAnimeId(e.target.value)}
            placeholder="Anime ID"
          />
        </div>
        <div className="input-group">
          <label htmlFor="seriesNum">Номер серии:</label>
          <input
            type="number"
            id="seriesNum"
            value={seriesNum}
            onChange={(e) => setSeriesNum(e.target.value)}
            placeholder="Номер серии"
          />
        </div>
        <div className="input-group">
          <label htmlFor="seasonNum">Номер сезона:</label>
          <input
            type="number"
            id="seasonNum"
            value={seasonNum}
            onChange={(e) => setSeasonNum(e.target.value)}
            placeholder="Номер сезона"
          />
        </div>
        <div className="input-group">
          <label htmlFor="url">Сервер загрузки</label>
          <input
            type="text"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Сервер загрузки"
          />
        </div>
        <div className="input-group">
          <label htmlFor="priority">Приоритет</label>
          <input
            type="number"
            id="priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            placeholder="Приоритет > 0"
          />
        </div>
        <div className="input-group">
          <label htmlFor="key">Секретный токен</label>
          <input
            type="text"
            id="key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            placeholder="Секретный токен"
          />
        </div>
        <input
          type="checkbox"
          id="available"
          name="available"
          checked={notify}
          onChange={(e) => setNotify( e.target.checked)}
        />

        <label className='label-switch' htmlFor="available">Уведомить</label>
        <button type="submit" disabled={loading}>Загрузить</button>
        {loading && (
          <div>
            <div className="loader"></div>
            <span className='text'>Загрузка...</span>
          </div>
        )}
      </form>
      {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
      {successMessage && <div style={{ color: 'green', textAlign: 'center' }}>{successMessage}</div>}
    </div>
  );
};

export default UploadAnimeComponent;
