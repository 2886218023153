import React, { useState, useEffect } from 'react';
import './../../App.css'; // Assuming you have a CSS file for styling

import Header from './../../components/Header';
import Footer from '../../components/Footer';
import ApiService from '../../services/ApiService';

import { useParams, useNavigate } from 'react-router-dom';
import ProfilePic from '../../components/profilepic/ProfilePic';

const ReviewsAdminPage = () => {
    // State variables
    const [scrollPosition, setScrollPosition] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reviews, setReviews] = useState([]);
    const [page, setPage] = useState(0);
    const { id } = useParams();

    // Fetch data from API
    const fetchData = async () => {
        setReviews([]);
        const data = await ApiService.getAdminReviews(page);
        if (data.type === "success") {
            setReviews(data.data);
        }
    };

    // Fetch data on initial render and when page changes
    useEffect(() => {
        const handleScroll = () => {
            const position = window.innerHeight + window.scrollY;
            setScrollPosition(position);
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        fetchData(); // Initial data fetch
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page]); // Trigger fetchData when page changes

    // Format date function
    function formatDate(milliseconds) {
        // ... (existing code for formatting date)
    }

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                <div className="page-selector">
                    <button onClick={() => setPage(Number.parseInt(page) - 1)}>Назад</button>
                    <button onClick={() => setPage(Number.parseInt(page) + 1)}>След страница</button>
                    <input
                        type="number"
                        value={page}
                        onChange={(e) => setPage(e.target.value)}
                        placeholder="Enter page number"
                    />
                </div>
                <div className="container-wrapper">
                    {reviews.map((review) => (
                        <div className={`user-admin ${review.user.banned ? "ban" : ""}`}>
                         

                            <div className="review-admin">
                                <p>{review.message}</p>
                                <a href={`/profile/${review.user.nickname}`}>Профиль </a>
                                <a href={`/anime/${review.animeId}`}> Аниме</a>
                                <button onClick={async () => {
                                    var ans = await ApiService.banUser(review.user.id, !review.user.banned);
                                    if (ans.type == "success") {
                                        fetchData();
                                    }
                                }} className='button-sec'> {review.user.banned ? "Разбанить" : "Забанить"}</button>
                            </div>
                            <div className='review-user-section'>
                                {review != null ? <ProfilePic isBig={false} account={review.user} /> : ""}
                                <p>{review.user.nickname}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="page-selector">
                    <button onClick={() => setPage(page - 1)}>Назад</button>
                    <button onClick={() => setPage(page + 1)}>След страница</button>
                    <p>стр {page}</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReviewsAdminPage;
