import React, { useState, useEffect, useRef } from 'react';
import './../App.css';
import Header from './../components/Header';
import Footer from '../components/Footer';
import ApiService from '../services/ApiService';
import AnimeCardComponent from '../components/animecard/AnimeCardComponent';
import { useParams } from 'react-router-dom';

const CollectionPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    let thatsAll = false;
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [collections, setCollections] = useState([]);
    const [animeIds, setAnimeIds] = useState([]);
    const initialized = useRef(false);

    const { id } = useParams();
    let fetchedCollections = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!fetchedCollections.current) {
                fetchedCollections.current = true;
                const catData = await ApiService.getCollections(id);
                if (catData.type === "success") {
                    setCollections(catData.data);
                    if (catData.data.length > 0) {
                        const initialCollection = catData.data[0];
                        setSelectedCollection(initialCollection);
                        setAnimeIds(initialCollection.animeIds);
                    }
                }
            }
        };

        fetchData();
    }, [id]);


    var currentLoadingPage = -1;

    const fetchAnimeData = async () => {
        if (animeIds.length === 0 || currentLoadingPage == page) return;
        try {
            setLoading(true);
            currentLoadingPage = page;
            const startIndex = page * 10;
            const endIndex = startIndex + 10;
            const idsToFetch = animeIds.slice(startIndex, endIndex);

            if (idsToFetch.length === 0) {
                thatsAll = true;
                return;
            }

            const data = await ApiService.getAnimeByIds(idsToFetch);
            if (data.type === "success") {
                setAnimeList(prevAnimeList => [...prevAnimeList, ...data.data]);
                setPage(prevPage => prevPage + 1);

                // Check if more data is needed immediately after loading
                if (document.documentElement.scrollHeight <= window.innerHeight && data.data.length > 0) {
                    fetchAnimeData();
                }
            } else {
                console.error('Error fetching anime list:', data);
            }
        } catch (error) {
            console.error('Error fetching anime list:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        const position = window.innerHeight + window.scrollY;
        const bottom = document.documentElement.offsetHeight;
        if (position >= bottom) {
            fetchAnimeData();
        }
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        if (!initialized.current && selectedCollection) {
            initialized.current = true;
            fetchAnimeData();
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [selectedCollection, animeIds, page, thatsAll, loading]);

    useEffect(() => {
        // Check if we need to load more data when animeList changes
        if (document.documentElement.scrollHeight <= window.innerHeight && !loading && !thatsAll) {
            fetchAnimeData();
        }
    }, [animeList]);

    const handleCollectionChange = (collection) => {
        thatsAll = false;
        setSelectedCollection(collection);
        setAnimeIds(collection.animeIds);
        setAnimeList([]);
        setPage(0);
        initialized.current = false;

        // Immediately check if we need to fetch more data
        fetchAnimeData();
    };

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                {loading && !selectedCollection ? (
                    <p>Загружаем...</p>
                ) : (
                    <div className="container-wrapper">
                        <h1 className='tasty-title'>Коллекции</h1>
                        <div className='tasty-underline'></div>

                        <div className="collection-selectors">
                            {collections.map((collection) => (
                                <button
                                    style={{ margin: '4px' }}
                                    key={collection.id}
                                    className={selectedCollection === collection ? 'tab-button selected' : 'tab-button'}
                                    onClick={() => handleCollectionChange(collection)}
                                >
                                    {collection.name}
                                </button>
                            ))}
                        </div>

                        <div className="anime-container" style={{ marginTop: '28px' }}>
                            {animeList.map((anime, index) => (
                                <AnimeCardComponent key={index} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {loading && !thatsAll && <p>Загружаем больше...</p>}
                        {thatsAll && <p>Это всё аниме в этой коллекции.</p>}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default CollectionPage;
