import React, { useState } from 'react';
import ApiService from '../services/ApiService';

const LeaveReviewComponent = ({ animeId, onSubmit }) => {
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(5);
  const [hoveredIndex, setStarHoveredIndex] = useState(null);

  const handleInputChange = (event) => {
    setReviewText(event.target.value);
  };

  const handleRatingChange = (starIndex) => {
    setRating(starIndex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      var ans = await ApiService.leaveReview(reviewText, rating, animeId);
      onSubmit();
      // Show a success message or update the anime data
    } catch (error) {
      console.error('Error leaving review:', error);
    }
  };

  return (
    <div className="leave-review-container">
      <p className='settings-text-small'>Оставляя отзыв, Вы обязуетесь соблюдать Правила Сообщества</p>
      <form onSubmit={handleSubmit}>
        {Array.from({ length: 10 }, (_, index) => index + 1).map((index) => (
          <span
            key={index}
            onClick={() => handleRatingChange(index)}
            onMouseEnter={() => setStarHoveredIndex(index)} // Add this line
            onMouseLeave={() => setStarHoveredIndex(null)} // Add this line
            className={
              rating >= index ? 'star-selected' : 'star-unselected'
            }
          >
            <span style={{ color: hoveredIndex >= index ? '#7544B2' : '' }}>
              ★
            </span>
          </span>
        ))}
        <textarea
          style={{ height: 100, width: "80%" }}
          value={reviewText}
          onChange={handleInputChange}
          maxlength="200"
          placeholder="Поделиться впечатлениями"
          rows="2"
        />
        <div >
          <button type="submit">Оставить отзыв </button>


        </div>


      </form>
    </div>
  );



};

export default LeaveReviewComponent;
