import React, { useState, useEffect } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import logo from './../assets/logo_150.svg'; // Import logo.svg from the assets folder
import Header from './../components/Header';
import Footer from '../components/Footer';

const CommunityGuidelinesPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                <h1>Правила сообщества</h1>
                <p>
                    Ниже представлены актуальные правила сообщества, которые нужно соблюдать, загружая контент на нашу платформу:
                    <br />
                    <ul>
                        <li>
                            Не размещайте рекламу, не пытайтесь абузить механики сайта
                        </li>
                        <li>
                            Не размещайте спойлеры, не портите впечатление от просмотра другим пользователям
                        </li>
                        <li>
                            Уважайте других участников сообщества. Не публикуйте оскорбительный или унизительный контент.
                        </li>
                        <li>
                            Соблюдайте авторские права. Не загружайте контент, защищенный авторским правом, без разрешения владельца.
                        </li>
                        <li>
                            Не публикуйте непристойный или порнографический контент.
                        </li>
                        <li>
                            Не распространяйте ложную информацию или провокационные материалы.
                        </li>
                        <li>
                            Соблюдайте законы и нормы общественной морали при загрузке контента.
                        </li>
                        <li>
                            Не публикуйте контент, призывающий к насилию, дискриминации или ненависти по любым признакам.
                        </li>
                        <li>
                            Соблюдайте конфиденциальность. Не публикуйте личную информацию других людей без их разрешения.
                        </li>
                        <li>
                            Не загружайте вредоносный или вирусный контент.
                        </li>
                    </ul>
                </p>

                <p>
                    Если вы обнаружите нарушение правил сообщества, пожалуйста, сообщите администрации для принятия мер.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default CommunityGuidelinesPage;
