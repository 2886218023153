import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SkipMarkForm.css';
import ApiService from '../../services/ApiService';

const SkipMarkForm = ({ series, videoRef }) => {
    const [skipMarks, setSkipMarks] = useState([]);
    const [view, setView] = useState('list'); // 'list' or 'form'
    const [currentSkipMark, setCurrentSkipMark] = useState({
        animeId: series.animeId,
     
        season: series.season,
        seriesNum: series.seriesNum,
        supportedVideoIds: '',
        label: 'Опенинг',
        startTime: '',
        finishTime: '',
        autoSkip: false,
    });

    const saveSkipMark = async (skipMark) => {
        try {
            const response = await ApiService.updateSkipMark(skipMark);
            if (response.type == "success") {
                alert("Сохранено");
                fetchSkipMarks();
            }
            else
            {
                alert("Ошибка сохранения: " + JSON.stringify(response));
            }
        } catch (error) {
            console.error('Error fetching skip marks:', error);
        }
    };
    const fetchSkipMarks = async () => {
        try {
            const response = await ApiService.getSkipMarks(series.animeId, series.seriesNum, series.season);
            setSkipMarks(response.data);
        } catch (error) {
            console.error('Error fetching skip marks:', error);
        }
    };
    useEffect(() => {
       

        fetchSkipMarks();
    }, [series.animeId, series.seriesNum, series.season]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentSkipMark({ ...currentSkipMark, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCurrentSkipMark({ ...currentSkipMark, [name]: checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const parsedSkipMark = {
            ...currentSkipMark,
            animeId: Number(currentSkipMark.animeId),
            season: Number(currentSkipMark.season),
            seriesNum: Number(currentSkipMark.seriesNum),
            supportedVideoIds: currentSkipMark.supportedVideoIds.split(',').map(Number),
            startTime: Number(currentSkipMark.startTime),
            finishTime: Number(currentSkipMark.finishTime),
        };

        saveSkipMark(parsedSkipMark);
    };

    const handleEdit = (skipMark) => {
        setCurrentSkipMark({
            ...skipMark,
            supportedVideoIds: skipMark.supportedVideoIds.join(','),
        });
        setView('form');
    };

    const handleCreateNew = () => {
        setCurrentSkipMark({
            animeId: series.animeId,
      
            season: series.season,
            seriesNum: series.seriesNum,
            supportedVideoIds: '',
            label: 'Опенинг',
            startTime: '',
            finishTime: '',
            autoSkip: false,
        });
        setView('form');
    };

    return (
        <div>
            {view === 'list' ? (
                <div className='skip-mark-form'>
                    <h4>Метки</h4>
                    <ul>
                        {skipMarks.map((skipMark) => (
                            <li key={skipMark.id}>
                                <span>{skipMark.label}: {skipMark.startTime} - {skipMark.finishTime}</span>
                                <button onClick={() => handleEdit(skipMark)}>Edit</button>
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleCreateNew}>Создать метку</button>
                </div>
            ) : (
                <form className='skip-mark-form' onSubmit={handleSubmit}>
                    <p>эпизод {series.seriesNum}, сезон {series.season}, videoId {series.id}</p>

                    <label>
                        Название:
                        <input type="text" name="label" value={currentSkipMark.label} onChange={handleChange} required />
                    </label>
                    <label>
                        Начало:
                        <input type="number" name="startTime" value={currentSkipMark.startTime} onChange={handleChange} required />
                    </label>
                    <button type="button" onClick={() => { setCurrentSkipMark({ ...currentSkipMark, startTime: Math.floor(videoRef.current.currentTime) }) }}>Записать</button>
                    <label>
                        Окончание:
                        <input type="number" name="finishTime" value={currentSkipMark.finishTime} onChange={handleChange} required />
                    </label>
                    <button type="button" onClick={() => { setCurrentSkipMark({ ...currentSkipMark, finishTime: Math.floor(videoRef.current.currentTime) }) }}>Записать</button>
                    <button type="button" onClick={() => {
                        const sliderEl = document.querySelector("#range");
                        videoRef.current.pause();

                        let startPercent = currentSkipMark.startTime / videoRef.current.duration * 100;
                        let finishPercent = currentSkipMark.finishTime / videoRef.current.duration * 100;

                        sliderEl.style.background = `linear-gradient(to right, #ffffff4d  ${startPercent}%,  #e4aa7a   ${startPercent}%, #e4aa7a  ${finishPercent}%, #ffffff4d   ${finishPercent}%)`;
                    }}>Проверить</button>
                    <label>
                        Автопропуск:
                        <select name="autoSkip" value={currentSkipMark.autoSkip} onChange={handleChange}>
                            <option value={true}>Да</option>
                            <option value={false}>Нет</option>
                        </select>
                    </label>
                    <button type="submit">Сохранить</button>
                    <button type="button" onClick={() => setView('list')}>Назад в список</button>
                </form>
            )}
        </div>
    );
};

export default SkipMarkForm;
