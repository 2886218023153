import React, { useEffect } from 'react';

const YandexAdComponent = ({ blockId }) => {
  useEffect(() => {
    const renderYandexAd = () => {
      if (localStorage.getItem("subscribed") === "true") return;
      if (window.Ya && window.Ya.Context && window.Ya.Context.AdvManager) {
        window.Ya.Context.AdvManager.render({
          blockId: blockId,
          renderTo: `yandex_rtb_${blockId}`
        });
      }
    };

    if (localStorage.getItem("subscribed") === "true") return;
    if (window.yaContextCb) {
      window.yaContextCb.push(renderYandexAd);
    } else {
      window.yaContextCb = [renderYandexAd];
    }
  }, [blockId]);

  return (
    <div>
      {localStorage.getItem("subscribed") === "true" ? null : (
        <div id={`yandex_rtb_${blockId}`} style={{ margin: '20px 0' }}></div>
      )}
    </div>
  );
};

export default YandexAdComponent;
