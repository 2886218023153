import React, { useState, useEffect } from 'react';
import './../../App.css'; // Assuming you have a CSS file for styling


import Header from './../../components/Header';
import Footer from '../../components/Footer';
import ApiService from '../../services/ApiService';

import { useParams, useNavigate } from 'react-router-dom';
import ProfilePic from '../../components/profilepic/ProfilePic';

const AdminUsersPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);

    const { id } = useParams();

    const fetchData = async () => {
        setUsers([]);
        const data = await ApiService.getUsers(page);

        if (data.type === "success") {
            console.log(JSON.stringify(data.data))
            setUsers(data.data);

        }

    };

    useEffect(() => {
    
        const handleScroll = () => {
            const position = window.innerHeight + window.scrollY;
            const bottom = document.documentElement.offsetHeight;

            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        fetchData(); // Initial data fetch

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page]); // Trigger fetchData when page changes

    function formatDate(milliseconds) {
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];

        const date = new Date(milliseconds);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const hour = date.getHours();
        const minutes = date.getMinutes();

        return `${day} ${months[monthIndex]} ${year} в  ${hour}:${ minutes}`;
    }


    return (

        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">

                <div className="page-selector">
                    <button onClick={() => setPage(page - 1)}>Назад</button>
                    <button onClick={() => setPage(page + 1)}>След страница</button>
                    <input
                        type="number"
                        value={page}
                        onChange={(e) => setPage(e.target.value)}
                        placeholder="Enter page number"
                    />
                </div>

                <div className="container-wrapper">

                    {users.map((user) => (
                        <div className={`user-admin ${user.banned ? "ban" : ""}`}>
                            {user != null ? <ProfilePic isBig={false} account={user}/>  : ""} 
                            <p>{user.nickname  }</p>

                            <a href={`/profile/${user.nickname}`}>Профиль</a>
                            <button onClick={ async () => {
                               var ans =  await ApiService.banUser(user.id, !user.banned);
                               if(ans.type == "success")
                                {
                                    fetchData();
                                }
                            }} className='button-sec'> {user.banned ? "Разбанить" : "Забанить"}</button>
                            <p>рег {formatDate(user.registrationDate)  + " " + user.region}</p>
                        </div>
                    ))}
                </div>


                <div className="page-selector">
                    <button onClick={() => setPage(page - 1)}>Назад</button>
                    <button onClick={() => setPage(page + 1)}>След страница</button>
                    <p>стр {page}</p>
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default AdminUsersPage;
