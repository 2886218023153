// React Component for Mobile Devices
import React, {useEffect, useRef, useState} from 'react';
import './Header.css';
import logo from './../assets/logo_150.svg';
import ProfilePic from './profilepic/ProfilePic';
import ApiService from '../services/ApiService';
import HeaderMobile from './HeaderMobile';
import ProfilePopup from './ProfilePopup';
import NotifyIcon from '../assets/ic_notification_header.svg';
import NotifyIconNew from '../assets/ic_notification_header_new.svg';
import NotificationPopup from './NotificationPopup';

const Header = ({scrolled}) => {
    const [searchResults, setSearchResults] = useState({anime: [], user: []});
    const [searchQuery, setSearchQuery] = useState('');
    const searchRef = useRef(null); // Ref to the search input
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupNot, setShowPopupNot] = useState(false);
    const [showNewNot, setShowNewNot] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
        setShowPopupNot(false);

    };
    const [isMobile, setIsMobile] = useState(false);

    // Function to perform search
    const handleSearch = async (query) => {
        setSearchQuery(query); // Update searchQuery state
        if (query.length < 2) return; // Don't make API call if query is less than 3 characters
        try {
            const results = await ApiService.search(query);

            if (results.type == "success") {
                setSearchResults(results.data);
            }

        } catch (error) {
            console.error('Error occurred while searching:', error);
            setSearchResults({anime: [], user: []});
        }
    };


    var notificationFetched = false;

    useEffect(() => {
        const fetchNotifications = async () => {
            if (notificationFetched) return;
            notificationFetched = true;
            try {
                const data = await ApiService.getNotifications(0);
                if (data.data[0].id != localStorage.getItem("seenNotId")) {

                    setShowNewNot(true);
                } else {
                    setShowNewNot(false);
                }

            } catch (error) {
                console.error('Failed to fetch notifications:', error);
            }
        };

        fetchNotifications();
    }, []);
    // Function to clear search results
    const clearSearchResults = () => {
        setSearchResults({anime: [], user: []});
    };

    // Function to handle clicking outside of the search bar
    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            clearSearchResults();
        }
    };

    // Add event listener to handle clicking outside of the search bar
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1100); // Adjust the breakpoint as needed
        };

        // Initial check on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Check if sessionKey exists in local storage
    const sessionKey = localStorage.getItem('sessionKey');

    return (
        <div>
            {isMobile ? <HeaderMobile/> : <header className={`header ${scrolled ? 'headerScrolled' : ''}`}>
                <a href="/">
                    <img className="logo" src={logo} alt="Logo"/>
                </a>
                <div className='rightContainer'>
                    <a className='text-buttons-header' href={`/leaderboard`}><h3>Лидерборд</h3></a>
                    <a className='text-buttons-header' href={`/collection/${localStorage.getItem("profileId")}`}><h3>Моя
                        коллекция</h3></a>
                    <div className="searchBar" ref={searchRef}>

                        <input
                            type="text"
                            placeholder="Поиск"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                        {searchResults.anime.length > 0 | searchResults.user.length > 0 ? (
                            <div className="searchResultsBox">
                                {searchResults.anime.map((anime) => (
                                    <a className='search-result' key={anime.id} href={`/anime/${anime.id}`}>
                                        <p className='search-result-text'>{anime.name}</p>
                                        <p className={`search-rating ${anime.rating >= 7.5 ? anime.rating >= 9 ? "premium" : "good" : ""}`}>★ {anime.rating}</p>
                                    </a>
                                ))}
                                {searchResults.user.map((user) => (
                                    <a className='search-result' key={user.id} href={`/profile/${user.nickname}`}>
                                        <ProfilePic className="accountImage" account={user} style={{"scale": 0.2}}/>
                                        <p className='search-result-text'
                                           style={{"margin-left": 10}}>{user.nickname}</p>
                                        {/* <p style={{"opacity": 0.6, "margin": 0}}>Профиль</p> */}

                                    </a>
                                ))}
                            </div>
                        ) : ""}
                    </div>
                    {sessionKey ? (
                        <div>
                            <img src={showNewNot ? NotifyIconNew : NotifyIcon} alt="Play Icon" className='notify-icon'
                                 onClick={() => {
                                     setShowPopupNot(!showPopupNot);
                                     setShowPopup(false);
                                 }}/>
                            <div className="profile-container" onClick={togglePopup} style={{cursor: 'pointer'}}>
                                <ProfilePic className="accountImage"/>
                            </div>
                        </div>

                    ) : (
                        <a className="loginButton" href="/login">Войти</a>
                    )}

                    <ProfilePopup visible={showPopup}/>
                    {showPopupNot ? <NotificationPopup visible={showPopupNot} onClose={() => setShowPopupNot(false)}/> : ""}
                </div>
            </header>}
        </div>
    );
};

export default Header;
