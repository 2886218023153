import React, { useState, useEffect } from 'react';
import './../../App.css'; // Assuming you have a CSS file for styling

 // Import logo.svg from the assets folder
import Header from './../../components/Header';
import Footer from './../../components/Footer';
import UploadAnimeComponent from './UploadComponent';

const UploadPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
   
    useEffect(() => {
      const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
      };
      document.title = "Выгрузка";
      window.addEventListener('scroll', handleScroll, { passive: true });
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  

  
    return (
      <div className="app">
        <Header scrolled={scrollPosition > 0} />
        <UploadAnimeComponent/>
        <Footer/>
     
    
      </div>
    );
};

export default UploadPage;
