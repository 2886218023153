import React, { useState, useEffect } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import Header from './../components/Header';
import Footer from '../components/Footer';
import ApiService from '../services/ApiService';
import AnimeCardComponent from '../components/animecard/AnimeCardComponent';

const AllAnimePage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [thatsAll, setThatsAll] = useState(false);

    let lastFetchPage = -1; // Use 'let' instead of 'var'

    const fetchData = async (locPage) => {
        if (thatsAll || lastFetchPage == locPage) return; // Exit if already loading or all data has been fetched
        try {
            setLoading(true);
            lastFetchPage = locPage;
            const data = await ApiService.getPublicAnimeList(locPage);
            if (data.type === "success") {
                if (data.data.length === 0) {
                    setThatsAll(true); // Set flag indicating all data has been fetched
                } else {
                    setAnimeList(prevAnimeList => [...prevAnimeList, ...data.data]);
                }
            } else {
                console.error('Error fetching anime list:', data);
            }
        } catch (error) {
            console.error('Error fetching anime list:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        const position = window.innerHeight + window.scrollY;
        const bottom = document.documentElement.offsetHeight;
        if (position >= bottom) {
            setPage(prevPage => prevPage + 1); // Increment page when scrolled to bottom
        }
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const checkAndFetchMore = async () => {
            await fetchData(page);
            if (document.documentElement.offsetHeight <= window.innerHeight && !thatsAll) {
                setPage(prevPage => prevPage + 1);
            }
        };

        checkAndFetchMore();
    }, [page]); // Fetch data when page changes

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                <div className="container-wrapper">
                    <h1 className='tasty-title'>Недавно обновлено</h1>
                    <div className='tasty-underline'></div>
                    <div className="anime-container">
                        {animeList.map((anime, index) => (
                            <AnimeCardComponent key={index} anime={anime} isInteractive={true} />
                        ))}
                    </div>
                    {(loading && !thatsAll) && <p>Загружаем больше...</p>}
                    {thatsAll && <p>Это всё недавно загруженное аниме.</p>}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AllAnimePage;
