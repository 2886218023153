import React, {useEffect, useState} from 'react';
import './../../App.css';
import './ProfilePage.css';
import {useNavigate, useParams} from 'react-router-dom';
import Header from '../../components/Header';
import placeholderImage from './../../assets/placeholder.png';
import ApiService from '../../services/ApiService';
import ProfilePic from '../../components/profilepic/ProfilePic';
import timeIcon from '../../assets/time.svg';
import statsIcon from '../../assets/stats.svg';
import giftIcon from '../../assets/ic_gift.svg';
import friendIcon from '../../assets/friend.svg';
import addFriendIcon from '../../assets/addFriend.svg';
import removeFriendIcon from '../../assets/removeFriend.svg';
import telegramIcon from '../../assets/telegramIcon.svg';
import premium from '../../assets/premium_badge.svg';
import {Doughnut} from 'react-chartjs-2';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import WatchCardComponent from '../../components/watchcard/WatchCardComponent';
import Footer from '../../components/Footer';
import FriendsPopup from './FriendsPopup';
import ReviewComponent from '../../components/ReviewComponent';
import AnimixPremiumGiftPopup from '../../components/premium/AnimixPremiumGiftPopup';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProfilePage = () => {
    const {nickname} = useParams();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [account, setAccount] = useState(null);
    const [email, setEmail] = useState("dummy");
    const [seriesData, setSeriesData] = useState(null);
    const [showEditPopup, setShowUploadPopup] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [description, setDescription] = useState('');
    const [nick, setNickname] = useState(nickname);
    const [backgroundLoaded, setBackgroundLoaded] = useState(false);
    const [frames, setFrames] = useState([]);
    const [allFrames, setAllFrames] = useState([]);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [availableFrameIds, setAvailableFrameIds] = useState(null);
    const [error, setError] = useState(null);
    const [watchHistory, setWatchHistory] = useState([]);
    const [data, setData] = useState(null);
    const [collections, setCollections] = useState({});
    const [watchDataPage, setWatchDataPage] = useState(0); // Current page
    const [hasMore, setHasMore] = useState(true); // To track if there's more data

    const [telegram, setTelegram] = useState(''); // Add Telegram state
    const navigate = useNavigate();
    const [showFriendsPopup, setShowFriendsPopup] = useState(false);
    const [showGiftPopup, setShowGiftPopup] = useState(false);


    const [comments, setComments] = useState([]);
    const [commentPage, setCommentPage] = useState(0);
    const [hasMoreComments, setHasMoreComments] = useState(true);
    const [newComment, setNewComment] = useState('');

    const customOptions = {
        responsive: true,
        borderWidth: 0,
        layout: {
            padding: {
                left: 0,
                top: 0,
                bottom: 0,
            },
        },
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
            legend: {
                borderWidth: 0,
                position: 'right',
                labels: {
                    color: "#fff",
                    useBorderRadius: true,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        family: "Montserrat",
                        size: 16,
                        weight: "bold"
                    },
                }
            },
        }
    };


    const handleImageError = () => {
        setBackgroundImageUrl(null);
    }

    const handleBackgroundLoad = () => {
        setBackgroundLoaded(true);
    }

    // for some reason ui redraws for the second time
    var isProfileFetched = false;

    const fetchComments = async (id) => {
        try {
            const commentsData = await ApiService.getComments("comments_" + id, commentPage);
            // console.log(JSON.stringify(commentsData.data))
            setCommentPage(prevPage => prevPage + 1);
            if (commentsData.data.length > 0) {
                setComments(prevComments => [...prevComments, ...commentsData.data]);

            } else {
                setHasMoreComments(false);
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };


    const loadMoreComments = () => {

        fetchComments(account.id);
    };

    const handleNewCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleLeaveComment = async () => {
        if (newComment.trim() === '') return;
        try {
            const response = await ApiService.leaveComment(newComment, "comments_" + account.id);
            if (response.type === 'success') {
                setComments([response.data, ...comments]);

                setNewComment('');
            } else {
                console.error('Failed to leave comment:', response.message);
            }
        } catch (error) {
            console.error('Error leaving comment:', error);
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isProfileFetched) {
                    isProfileFetched = true;

                    console.log('useEffect triggered');
                    let data;
                    if (nickname) {
                        data = await ApiService.get(nickname);
                    } else {
                        data = await ApiService.getSelf();
                        let email = await ApiService.getEmail();

                        setEmail(email.data)
                    }


                    if (data.type === "success") {
                        console.log('Data fetched successfully');
                        if (!nickname) {
                            navigate(`/profile/${data.data.nickname}`);

                        }

                        setAccount(data.data);
                        fetchComments(data.data.id);
                        if (data.data.banned) return;
                        setNickname(data.data.nickname);
                        setBackgroundImageUrl(`https://api.animix.lol/account/getProfileData/${data.data.id}_profile_background.png`);
                        setDescription(data.data.description);

                        setTelegram(data.data.telegram); // Set Telegram data
                        document.title = "Профиль " + data.data.nickname;

                       

                        // Fetch initial watch history
                        const watchData = await ApiService.getWatchHistoryProfile(data.data.id, watchDataPage);
                        setWatchHistory(watchData.data);

                        // Fetch frames data
                        const framesData = await ApiService.getFrames();
                        const availableFrameIds = [...data.data.availablePicFrames, 0];
                        setAvailableFrameIds(availableFrameIds);
                        const availableFrames = framesData.filter(frame => availableFrameIds.includes(frame.id));
                        setFrames(availableFrames);
                        setAllFrames(framesData);

                        framesData.forEach(frame => {
                            if (frame.id == data.data.selectedPicFrame) {
                                setSelectedFrame(frame);


                            }

                        });

                         // Fetch collections data
                         const collectionsData = await ApiService.getCollections(data.data.id);
                         const collectionsStat = {};
                         collectionsData.data.forEach(element => {
                             if (element.system) {
                                 if (element.name === "Просмотрено") {
                                     collectionsStat.watched = element.animeIds.length;
                                 }
                                 if (element.name === "Брошено") {
                                     collectionsStat.brosheno = element.animeIds.length;
                                 }
                                 if (element.name === "Отложено") {
                                     collectionsStat.delayed = element.animeIds.length;
                                 }
                                 if (element.name === "В процессе") {
                                     collectionsStat.progress = element.animeIds.length;
                                 }
                                 if (element.name === "В планах") {
                                     collectionsStat.plans = element.animeIds.length;
                                 }
                             }
                         });
                         setData({
                             labels: ['Просмотрено', 'Брошено', 'Отложено', 'В процессе', 'В планах'],
                             datasets: [{
                                 data: [
                                     collectionsStat.watched,
                                     collectionsStat.brosheno,
                                     collectionsStat.delayed,
                                     collectionsStat.progress,
                                     collectionsStat.plans,
                                 ],
                                 backgroundColor: [
                                     '#79E936', // Просмотрено
                                     '#EB5744', // Брошено
                                     '#FDCF2A', // Отложено
                                     '#929292', // В процессе
                                     '#36B69C'  // В планах
                                 ],
                             }]
                         });

                    } else {
                        setNotFound(true);
                    }
                }
            } catch (error) {
                setNotFound(true);
                console.error('Error fetching profile data:', error);
            }
        };

        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, {passive: true});
        fetchData();
        return () => {
            console.log('Cleanup');
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const fetchWatchHistory = async (page) => {
        if (watchDataPage === page) return;
        setWatchDataPage(page);
        try {
            const watchData = await ApiService.getWatchHistoryProfile(account.id, page);
            if (watchData.data.length > 0) {
                setWatchHistory(prevHistory => [...prevHistory, ...watchData.data]);
            } else {
                setHasMore(false); // No more data to load
            }
        } catch (error) {
            console.error('Error fetching watch history:', error);
        }
    };


    function formatDate(milliseconds) {
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];

        const date = new Date(milliseconds);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return `${day} ${months[monthIndex]} ${year}`;
    }


    const handleAddFriend = async () => {
        try {
            const response = await ApiService.addFriend(account.id); // Call ApiService to add friend
            if (response.type === 'success') {
                setAccount(prevAccount => ({
                    ...prevAccount,
                    myFriend: true
                }));
            } else {
                console.error('Failed to add friend:', response.message);
            }
        } catch (error) {
            console.error('Error adding friend:', error);
        }
    };
    const toggleFriendsPopup = () => {
        setShowFriendsPopup(!showFriendsPopup);
    };


    const handleRemoveFriend = async () => {
        try {
            const response = await ApiService.removeFriend(account.id); // Call ApiService to add friend
            if (response.type === 'success') {

                setAccount(prevAccount => ({
                    ...prevAccount,
                    myFriend: false
                }));
            } else {
                console.error('Failed to add friend:', response.message);
            }
        } catch (error) {
            console.error('Error adding friend:', error);
        }
    };

    return (
        <div>
            <Header scrolled={scrollPosition > 0}/>
            {account != null ? (
                <div>
                    <div className="profile-info-content">
                        <img src={backgroundImageUrl == null ? placeholderImage : backgroundImageUrl}
                             onError={handleImageError} alt="Background" onLoad={handleBackgroundLoad}
                             className={`profile-header-photo ${backgroundLoaded ? "visible" : ""} ${account.subscribed ? "sub" : ""}`}/>
                        <div className='anime-info-container'>
                            <ProfilePic isBig={true} account={account ?? {}}/>
                            <div className='anime-info-div'>
                                <div className='profile-roles'>
                                    {account.mod ? <a className='mod-text'>Модерация</a> : ""}
                                    {account.editor ? <a className='editor-text'>Редактор</a> : ""}
                                </div>
                                <h1 className='anime-name'>{account.nickname} {account.subscribed ?
                                    <img style={{height: 40, paddingBottom: 5, verticalAlign: 'middle'}}
                                         src={premium}/> : ""}</h1>

                                <p className='profile-desc'>{account.description ? account.description.length > 0 ? account.description : "Нет описания" : "Нет описания"}</p>
                                <p className='profile-reg-date'>Рамка {selectedFrame != null ? selectedFrame.desc : ""},
                                    Зарегистрирован {formatDate(account.registrationDate)}</p>


                            </div>
                        </div>
                    </div>
                    <div className='profile-half-con'>
                        <div class="anime-half-desc">
                            <div className='profile-card'>
                                <div className='horizontal-container'>
                                    {account.telegram ? <a className='profile-button' href={account.telegram}>
                                        <img src={telegramIcon}></img>
                                    </a> : ""}


                                    {

                                        localStorage.getItem("profileId") != account.id ? (
                                            <button className='profile-button' href={account.telegram} onClick={() => {
                                                account.myFriend ? handleRemoveFriend() : handleAddFriend()
                                            }}>
                                                <img src={account.myFriend ? removeFriendIcon : addFriendIcon}></img>
                                                <p>{account.myFriend ? "Убрать" : "Добавить"}</p>
                                            </button>
                                        ) : (
                                            ""
                                        )}

                                    {localStorage.getItem("profileId") != account.id && !account.subscribed ? (
                                        <button className='gift-profile-button' onClick={() => {
                                            setShowGiftPopup(true);
                                        }}>
                                            <img src={giftIcon}></img>

                                        </button>
                                    ) : (
                                        ""
                                    )}


                                </div>

                            </div>
                            <div className='profile-card'>
                                <div className='horizontal-container'>
                                    <img src={friendIcon} className='profile-card-icon'></img>
                                    <h3 className='profile-card-title'>{account.friendsList.length} друзей</h3>


                                </div>
                                <div className='profile-friends-con'>
                                    {account.friendsEntities.slice(0, 6).map((account, index) => (
                                        <a href={`/profile/${account.nickname}`}> <ProfilePic key={index} isBig={false}
                                                                                              account={account}/></a>

                                    ))}
                                </div>
                                <h3 className='button-sec' onClick={() => {
                                    toggleFriendsPopup()
                                }} style={{textAlign: 'center', marginTop: 10}}>Все друзья</h3>
                            </div>
                            <div className='profile-card'>
                                <div className='horizontal-container'>
                                    <img src={statsIcon} className='profile-card-icon'></img>
                                    <h3 className='profile-card-title'>Статистика просмотра</h3>
                                </div>
                                <h1 className='profile-card-time-big'>{Math.round(account.watchCount * 20 / 60 * 10) / 10} часов
                                    просмотра</h1>
                                <h3 className='profile-card-time'>или {account.watchCount} серий</h3>
                                <div className='pie-chart-container'>
                                    {data ? <Doughnut data={data} options={customOptions}/> : ""}
                                </div>
                                <a href={`/collection/${account.id}`}><h3 className='button-sec'
                                                                          style={{textAlign: 'center'}}>Смотреть
                                    коллекции</h3></a>
                            </div>
                        </div>
                        <div class="anime-half">
                            <div className='profile-card'>
                                <div className='horizontal-container'>
                                    <img src={timeIcon} className='profile-card-icon'></img>
                                    <h3 className='profile-card-title'>Недавно просмотренное</h3>
                                </div>
                                <div className="watch-his-container">
                                    {watchHistory.map((watchSnippet) => (
                                        <WatchCardComponent key={watchSnippet.id} preview={true}
                                                            watchSnippet={watchSnippet} isInteractive={true}/>
                                    ))}
                                    {hasMore && (
                                        <button className="button-sec"
                                                style={{textAlign: 'center', margin: 0, marginTop: 10, width: '100%'}}
                                                onClick={() => {
                                                    fetchWatchHistory(watchDataPage + 1);
                                                }}>
                                            Показать ещё
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        {localStorage.getItem("profileId") == account.id & account.subscribed & !email.includes("@") && email != "dummy" ? (
                            <div className="popup-container">
                                <div className="popup">
                                    <h2>Привяжите почту к аккаунту</h2>
                                    <p>Добавьте почту, чтобы сохранить доступ к аккаунту и статистике</p>
                                    <button className='button-sec' onClick={() => navigate('/settings')}>В настройки
                                    </button>
                                </div>
                            </div>
                        ) : ""}

                        {account.banned && (
                            <div className="popup-container">
                                <div className="popup">
                                    <h2>Этот профиль недоступен</h2>
                                    <p>Пользователь был заблокирован за нарушение правил сообщества</p>
                                    <button className='button-sec' onClick={() => navigate('/')}>На главную</button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='comments-profile'>
                        <h1>Комментарии</h1>
                        <div className='comment-form'>

                            <textarea
                                value={newComment}
                                maxlength="200"
                                onChange={handleNewCommentChange}
                                placeholder='Оставить комментарий...'
                            />
                            <p className='settings-text-small' style={{margin: "10px 10px"}}>Оставляя комментарий, Вы
                                обязуетесь соблюдать Правила Сообщества</p>
                            <button className='button-sec' onClick={handleLeaveComment}>
                                Оставить комментарий
                            </button>

                        </div>
                        <div className='comment-section'>
                            {comments.map((comment) => (
                                <ReviewComponent review={comment} isReview={false}
                                                 deletable={localStorage.getItem("profileId") == account.id || localStorage.getItem("mod") == "true"}/>
                            ))}
                            {hasMoreComments && (
                                <button className="button-sec" onClick={loadMoreComments}>
                                    Загрузить ещё
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            ) : (
                <div className='loading-con'>{notFound ? "" : <div className="loader"></div>}
                    <h3>{notFound ? "Профиль " + nickname + " не найден" : ("Загружаем профиль " + (nickname != undefined ? nickname : ""))}</h3>
                </div>
            )}
            <Footer/>

            {showFriendsPopup && (
                <FriendsPopup
                    friends={account.friendsEntities}
                    closePopup={toggleFriendsPopup}
                />
            )}

            {showGiftPopup && (
                <AnimixPremiumGiftPopup
                    nickname={account.nickname}
                    onClose={() => {
                        setShowGiftPopup(false)
                    }}
                />
            )}


        </div>
    );
};

export default ProfilePage;
