import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SeriesCardComponent.css';

const SeriesCardComponent = ({ series, anime, onSelectAnime, currentId, single }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const imgRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !imageLoaded) {
                    loadImage();
                    observer.unobserve(entry.target);
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [imageLoaded]);

    const loadImage = () => {
        const imgElement = imgRef.current;
        if (imgElement) {
            imgElement.src = `https://api.animix.lol/anime/getThumb/${series.privateId}_thumb.png`;
            imgElement.onload = () => {
                setImageLoaded(true);
            };
        }
    };

    const handleSeriesClick = () => {
        navigate(`/watch/${anime.id}/${series.id}`);
        if (onSelectAnime != null) {
            onSelectAnime(series);
        }
    };

    return (
        <div className="series-card" onClick={handleSeriesClick}>
            <img
                ref={imgRef}
                alt={series.name}
                className={`series-thumbnail ${imageLoaded ? 'loaded' : ''} ${currentId != null ? (currentId == series.id ? 'current' : '') : ''}`}
            />
            <p className={`series-subname ${currentId ? (currentId == series.id && !single ? 'active' : '') : ''}`}>Текущая серия</p>
            <h4 className="series-name">{!single ? 'Серия ' + series.seriesNum : 'Фильм' }</h4>
        </div>
    );
};

export default SeriesCardComponent;
