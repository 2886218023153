const ErrorCodes = {
    getText: (response) => {
        try
        {
        if (response['data']['code'] === 0) {
           return "Регистрация недоступна в Вашем регионе";
            
        }
        else if (response['data']['code'] === 1) {
            return  "Пароль слишком простой";
            
        }
        else if (response['data']['code'] === 2) {
            return  "Логин не должен быть коротким или содержать спецсимволы";
            
        }
        else if (response['data']['code'] === 3) {
            return "Аккаунт с таким логином уже зарегистрирован";
            
        }
        else if (response['data']['code'] === 4) {
            return "Неверный пароль";
            
        }
        else if (response['data']['code'] === 5) {
            return "Пользователя не существует";
            
        }
        else if (response['data']['code'] === 6) {
            return "Доступ запрещён";
            
        }
        else if (response['data']['code'] === 7) {
            return "Неверный текст";
            
        }
        else if (response['data']['code'] === 8) {
            return "Видео уже существует";
            
        }
        else if (response['data']['code'] === 9) {
            return "Видео не загружено";
            
        }
        else if (response['data']['code'] === 10) {
            return "Контент недоступен";
            
        }
        else if (response['data']['code'] === 11) {
            return "Вы похожи на бота, попробуйте ещё раз";
            
        }
        else if (response['data']['code'] === 12) {
            return "Файл слишком больщой";
        }
        else if (response['data']['code'] === 13) {
            return "Слишком частая смена никнейма";
        }
        else if (response['data']['code'] === 14) {
            return "Неверная ссылка";
        }
        else
        {
            return "Ошибка: " + response['data']['code'];
        }
    }
    catch (error) {
        return JSON.stringify(response)
    }
    }
};

export default ErrorCodes;
