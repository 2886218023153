import React, {useEffect, useRef, useState} from 'react';
import './animix-premium.css';
import sadCat from '../../assets/sad_cat.png'
import adFreeIcon from "../../assets/ad-free.svg";
import profileFrameIcon from "../../assets/profile-frame.svg";
import neuralRecommendationsIcon from "../../assets/neural-recommendations.svg";

const AnimixPremiumPopupCancel = ({onCancel, onClose}) => {
    const [time, setTime] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsVisible(false);
            if (onClose) onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isVisible) return null;

    switch (time) {
        case 1:
            return (
                <div className="prem-popup-container">
                    <div className="prem-cancel-popup-content" ref={popupRef}>
                        <h3>Вы потеряете доступ к тысячам серий любимых аниме без назойливой рекламы</h3>
                        <button
                            className="yearly-btn"
                            onClick={onClose}
                        >
                            Я передумал
                        </button>
                        <button
                            className="cancel-btn"
                            onClick={() => setTime(2)}
                        >
                            Всё-таки хочу отписаться
                        </button>
                    </div>
                </div>
            );
        case 2:
            return (
                <div className="prem-popup-container">
                    <div className="prem-cancel-popup-content" ref={popupRef}>
                        <h3>А как же эксклюзивная рамочка профиля? Художник ведь так старался...</h3>
                        <button
                            className="cancel-btn"
                            onClick={() => setTime(3)}
                        >
                            Я действительно хочу отписаться
                        </button>
                        <button
                            className="yearly-btn"
                            onClick={onClose}
                        >
                            Рамочка имба, не откажусь
                        </button>
                     
                    </div>
                </div>
            );
        case 3:
            return (
                <div className="prem-popup-container">
                    <div className="prem-cancel-popup-content" ref={popupRef}>
                        <h3>Премиум-подписка - не просто донат нам на улучшение анимикса. Отписавшись, Вы потеряете ещё ряд преимуществ:</h3>
                        <div className="prem-popup-feature">
                            <img src={adFreeIcon} alt="Ad Free"/>
                            <span>Без рекламы</span>
                        </div>
                        <div className="prem-popup-feature">
                            <img src={profileFrameIcon} alt="Special Profile Frame"/>
                            <span>Фон будет на всю страницу, а также на комментариях и отзывах + Специальная рамка</span>
                        </div>
                        <div className="prem-popup-feature">
                            <img src={neuralRecommendationsIcon} alt="Neural Recommendations"/>
                            <span>Нейронные рекомендации</span>
                        </div>
                        <button
                            className="yearly-btn"
                            onClick={onClose}
                        >
                            Я передумал
                        </button>
                        <button
                            className="cancel-btn"
                            onClick={onCancel}
                        >
                            Отписаться
                        </button>
                    </div>
                </div>
            );
        default:
            return (
                <div className="prem-popup-container">
                    <div className="prem-cancel-popup-content" ref={popupRef}>
                        <h3>Друг, ты правда хочешь отписаться и потерять всё, что даёт тебе статус премиума?</h3>
                        <div className="cancel-content">
                            <img src={sadCat} alt="Animix Premium" className="prem-popup-logo"/>
                        </div>
                        <button
                            className="yearly-btn"
                            onClick={onClose}
                        >
                            Ой-ой, случайно нажал :)
                        </button>
                        <button
                            className="cancel-btn"
                            onClick={() => setTime(1)}
                        >
                            Да, я хочу отписаться
                        </button>
                    </div>
                </div>
            );
    }
};

export default AnimixPremiumPopupCancel;
