import React, { useState, useEffect, useRef } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import Header from './../components/Header';
import Footer from '../components/Footer';
import ApiService from '../services/ApiService';
import AnimeCardComponent from '../components/animecard/AnimeCardComponent';
import { useParams } from 'react-router-dom';
import YandexAdComponent from '../components/YandexAdComponent';

const CategoryPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [thatsAll, setThatsAll] = useState(false);
    const [category, setCategory] = useState(null);
    const [animeIds, setAnimeIds] = useState([]);
    const initialized = useRef(false);
    
    const { id } = useParams();


    
    useEffect(() => {
        const fetchCategory = async () => {
            const catData = await ApiService.getPublicCategories();
            if (catData.type === "success") {
                const matchedCategory = catData.data.find(localCategory => localCategory.id == id);
                if (matchedCategory) {
                    setCategory(matchedCategory);
                    setAnimeIds(matchedCategory.animeIds);
                }
            }
        };

        fetchCategory();
    }, [id]);

    const fetchData = async () => {
        if (currentLoadingPage == page || loading || animeIds.length === 0) return;
        try {
            currentLoadingPage = page;
            setLoading(true);
            const startIndex = page * 10;
            const endIndex = startIndex + 10;
            const idsToFetch = animeIds.slice(startIndex, endIndex);

            if (idsToFetch.length === 0) {
                setThatsAll(true);
                return;
            }

            const data = await ApiService.getAnimeByIds(idsToFetch);
            if (data.type === "success") {
                setAnimeList(prevAnimeList => [...prevAnimeList, ...data.data]);
                setPage(prevPage => prevPage + 1);
            } else {
                console.error('Error fetching anime list:', data);
            }
        } catch (error) {
            console.error('Error fetching anime list:', error);
        } finally {
            setLoading(false);
        }
    };

    var currentLoadingPage = -1;
    useEffect(() => {
    
        const handleScroll = () => {
            const position = window.innerHeight + window.scrollY;
            const bottom = document.documentElement.offsetHeight;
            if (position >= bottom) {
                fetchData();
            }
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        if (!initialized.current && category) {
            initialized.current = true;
            fetchData();
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page, thatsAll, loading, animeIds, category]);

    useEffect(() => {
        // Check if we need to load more data when animeList changes
        if (document.documentElement.scrollHeight <= window.innerHeight && !loading && !thatsAll) {
            fetchData();
        }
    }, [animeList]);

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                {loading && !category ? (
                    <p>Загружаем...</p>
                ) : (
                    <div className="container-wrapper">
                        <h1 className='tasty-title'>{category && category.name}</h1>
                        <div className='tasty-underline'></div>
                        <YandexAdComponent blockId="R-A-8131234-1" />
                        <div className="anime-container">
                            {animeList.map((anime, index) => (
                                <AnimeCardComponent key={index} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {loading && !thatsAll && <p>Загружаем больше...</p>}
                        {thatsAll && <p>Это всё аниме в этой категории.</p>}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default CategoryPage;
