import React, {useEffect, useRef, useState} from 'react';
import placeholderImage from './../../assets/placeholder.png';
import  './FramePopup.css';
import { useNavigate } from 'react-router-dom';

const FramePopup = ({ availableFrames, selectedFrame, allFrames, handleFrameSelect, closePopup }) => {

    const navigate = useNavigate();
    const [frames, setFrames] = useState(availableFrames);
    const [myFrames, setMyFrames] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsVisible(false);
            if (closePopup) closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <div className="frame-popup-container">
            <div className="frame-popup" ref={popupRef}>
                <h2>Рамки</h2>
                <div className='frames-container'>
                    {frames.map(frame => (
                        <div key={frame.id} className={`frame-card ${selectedFrame === frame.id ? 'selected-frame' : ''}`}>
                            <div className="frame-label" onClick={() => { if (myFrames) { handleFrameSelect(frame) }
                        else
                        {
                            navigate (`/anime/${frame.animeId}`)
                        } }}>
                                <img src={frame.url ? frame.url : placeholderImage} alt={frame.title} className="frame-image" />
                                <div className="frame-info">
                                    <h4>{frame.title}</h4>
                                    <p>{frame.desc}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='horizontal-container'>
                    <input
                        type="checkbox"
                        id="myFrames"
                        name="myFrames"
                        checked={myFrames}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setFrames(availableFrames);

                            }
                            else {
                                setFrames(allFrames);
                            }
                            setMyFrames(e.target.checked)
                        }}

                    />
                    <label className='label-switch' htmlFor="myFrames"></label>
                    <a>Показать только мои</a>

                </div>
                <button className='button-sec' onClick={closePopup}>Закрыть</button>
            </div>
        </div>
    );
};

export default FramePopup;
