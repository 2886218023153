import React from 'react';
import profileIcon from './../assets/ic_profile.svg'; // Adjust the path as necessary
import settingsIcon from './../assets/ic_settings.svg';
import logoutIcon from './../assets/ic_switch.svg';
import './ProfilePopup.css'; // Import your CSS file for styling

const ProfilePopup = ({ visible }) => {


    return (

        <div className={`profile-popup ${visible ? "" : "gone"}`}>
            <ul>
                <a href="/profile">
                    <li>
                        <img src={profileIcon} alt="Profile" className="icon" />
                        <a href="/profile">Мой профиль</a>
                    </li>
                </a>
                <a href="/settings">
                    <li>

                        <img src={settingsIcon} alt="Settings" className="icon" />
                        <p >Настройки</p>


                    </li>
                </a>
                <a href="/login">
                    <li>
                        <img src={logoutIcon} alt="Logout" className="icon" />
                        <a href="/login">Сменить аккаунт</a>
                    </li>
                </a>
            </ul>
        </div>
    );
};

export default ProfilePopup;
