import React, { useState } from 'react';
import ProfilePic from "../components/profilepic/ProfilePic";
import "./ReviewComponent.css";
import premium from '../assets/premium_badge.svg';
import trashIcon from '../assets/ic_trash.svg';
import ApiService from '../services/ApiService';
import placeholder from '../assets/placeholder.png';

const ReviewComponent = ({ review, isReview = true, deletable = false }) => {
  const [rating, setRating] = useState(review.stars);
  const [deleted, setDeleted] = useState(false);

  function formatDate(milliseconds) {
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];

    const date = new Date(milliseconds);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[monthIndex]} ${year}`;
  }

  const handleDeleteComment = async () => {

    try {
      const response = await ApiService.deleteComment(review.id);
      if (response.type === 'success') {
        setDeleted(true);
      } else {
        console.error('Failed to leave comment:', response.message);
      }
    } catch (error) {
      console.error('Error leaving comment:', error);
    }
  };

  return (
    <div className={`review ${review.user.subscribed ? "sub" : ""} ${deleted ? "deleted" : ""}`}>
      <div> <div className='review-con'>

        {isReview ? <div className='star-con'>
          <a className={"star"}>★</a>

          <span className="stars-count">{rating}</span>
        </div> : ""}
        <p className="review-date" style={!isReview ? { margin: 0 } : {}}>{formatDate(review.date)}</p>
      </div>

        <p className='review-message'  >{review.user.banned ? "Содержимое недоступно" : review.message}</p>
        <div className='review-user-section'>

          <a className='review-user-section' href={`/profile/${review.user.nickname}`}>

            <ProfilePic isBig={false} account={review.user} />
            <p className='review-p'>{review.user.banned ? "Аккаунт недоступен" : review.user.nickname}  {review.user.subscribed ? <img style={{ height: 24, verticalAlign: 'middle' }} src={premium} /> : ""}</p>
          </a>
          {deletable && !deleted ? <img style={{ height: 40, display: "inline-block", paddingBottom: 5, verticalAlign: 'middle', "cursor": "pointer" }} src={trashIcon} onClick={handleDeleteComment} /> : ""}
        </div>

      </div>
      {review.user.subscribed ? <img style={{ opacity: 0.1 }} onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = placeholder;
      }} className="background-image" src={`https://api.animix.lol/account/getProfileData/${review.user.id}_profile_background.png`}></img> : ""}

    </div>
  );
};

export default ReviewComponent;