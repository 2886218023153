import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

let client;
let nickname;
let roomId;

export const connect = (_nickname, _roomId, onMessageReceived, onWatchTimeReceived, onPlayPauseReceived, onSeriesChangeReceived, onConnect) => {
    const socket = new SockJS('https://api.animix.lol/ws', null, {
        transports: ['xhr-polling']
    });
    nickname = _nickname;
    roomId = _roomId;

    client = new Client({
        webSocketFactory: () => socket,
        reconnectDelay: 5000,
        onConnect: () => {
            console.log('Connected to WebSocket');

            client.subscribe(`/topic/watchroom/${roomId}/chat`, message => {
                onMessageReceived(JSON.parse(message.body));
            });

            client.subscribe(`/topic/watchroom/${roomId}/watchTime`, message => {
                onWatchTimeReceived(JSON.parse(message.body));
            });

            client.subscribe(`/topic/watchroom/${roomId}/playPause`, message => {
                onPlayPauseReceived(JSON.parse(message.body));
            });

            // New subscription for series change
            client.subscribe(`/topic/watchroom/${roomId}/changeSeries`, message => {
                console.log( 'Series change received');
                const data = JSON.parse(message.body);
            
                console.log(JSON.stringify(data));
                onSeriesChangeReceived(JSON.parse(message.body));
            });

            onConnect();
            const message = { content: "", sender: `${nickname} подключился(-ась) к комнате` };
            sendMessage(`/app/chat/${roomId}`, message);
        },
        onWebSocketClose: (closeEvent) => {
            console.log('WebSocket closed: ', closeEvent);
            onMessageReceived({message: "", sender: "Вы были отключены от комнаты"});
        },
        onStompError: (frame) => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
        }
    });

    client.activate();
};

export const sendMessage = (destination, body) => {
    if (client && client.connected) {
        client.publish({ destination, body: JSON.stringify(body) });
    } else {
        // console.error('Cannot send message, STOMP client is not connected.');
    }
};

// Function to send series change message
export const sendSeriesChange = (animeId, seriesId, sender) => {
    const message = { message: JSON.stringify({animeId, seriesId}), sender: sender };
    sendMessage(`/app/changeSeries/${roomId}`, message);
};
