import React, { useEffect } from 'react';

const CountdownOverlay = ({ isCountdownActive, countdown, setCountdown, onCancel, text }) => {
    useEffect(() => {
        let timer;
        if (isCountdownActive && countdown > 0) {
            timer = setTimeout(() => {
                if (countdown > 0) {
                    setCountdown(countdown - 1);
                }
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCountdownActive, countdown, setCountdown]);

    return (
        isCountdownActive && (
            <div className="countdown-overlay">
                <div className="countdown-content">
                    <a>{text} через {countdown} секунд</a>
                    <button onClick={onCancel}>Отмена</button>
                </div>
            </div>
        )
    );
};

export default CountdownOverlay;
