import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from './../assets/logo.svg';
import defaultPlaceholder from './../assets/placeholder.png';
import ApiService from './../services/ApiService'; // Import ApiService
import ErrorCodes from '../services/ErrorCodes';

const LoginPage = () => {
    // State initialization
    const [placeholderImage, setPlaceholderImage] = useState(defaultPlaceholder);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [hasProblem, setHasProblem] = useState(false);
    const [problemText, setProblemText] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Function to get a random number
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        
        document.title = 'Вход в animix';
        const randomImageNumber = getRandomNumber(1, 3);

        try {
            // Import random placeholder image
            const randomImage = require(`./../assets/back_${randomImageNumber}.png`);
            setPlaceholderImage(randomImage);
        } catch (error) {
            console.error('Error loading random placeholder image:', error);
        }
    }, []);

    // Function to handle account creation
    const handleCreateAccount = async (event) => {
        event.preventDefault();

        // Check if passwords match and meet the length requirement
     
      

        try {
            // Call the registerUser function from ApiService
            setHasProblem(false);
            const response = await ApiService.login(login, password);
            // Handle the response, maybe show a success message or navigate to another page
           
            if (response["type"] === "success") {
                localStorage.setItem('sessionKey', response.data.key);
                navigate('/');
            }
            else {
                setHasProblem(true);
                setProblemText(ErrorCodes.getText(response));
            }


        } catch (error) {
            console.error('Error registering user:', error.message);
            setHasProblem(true);
            setProblemText("Неизвестная ошибка. ", error.message);
            // Handle registration error, maybe display an error message to the user
        }
    };

    // Function to handle login
    const handleRegister = () => {
        navigate('/register');
    };

    return (
        <div className="login-page">
            <div className="left-section">
                <div className="placeholder-container">
                    <img src={placeholderImage} alt="Placeholder" className="placeholder-image" />
                </div>
            </div>

            <div className="right-section">
                <a href="/">
                    <img className="login-logo" src={logo} alt="Logo" />
                </a>
                <form className="login-form" onSubmit={handleCreateAccount}>
                    {hasProblem && <p className="banner">{problemText}</p>}
                    <input type="text" placeholder="Логин" value={login} onChange={(e) => setLogin(e.target.value)} />
                    <input type="password" placeholder="Пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
              

                    <button type="submit" className='button-login'>Войти</button>
                    <button className='button-sec login' onClick={handleRegister}>Создать аккаунт</button>
                    <p className="privacy">Используя сайт, вы соглашаетесь с нашей политикой конфиденицальности</p>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
