import React, {useEffect, useRef, useState} from 'react';
import './HeaderMobile.css';
import logo from './../assets/logo_150.svg';
import ProfilePic from './profilepic/ProfilePic';
import ApiService from '../services/ApiService';
import ProfilePopup from './ProfilePopup';
import NotifyIcon from '../assets/ic_notification_header.svg';
import NotificationPopup from './NotificationPopup';

const HeaderMobile = ({scrolled}) => {
    const [searchResults, setSearchResults] = useState({anime: [], user: []});
    const [searchQuery, setSearchQuery] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const searchRef = useRef(null); // Ref to the search input
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupNot, setShowPopupNot] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }
    // Function to perform search
    const handleSearch = async (query) => {
        setSearchQuery(query); // Update searchQuery state
        if (query.length < 2) return; // Don't make API call if query is less than 3 characters
        try {
            const results = await ApiService.search(query);
            if (results.type == "success") {
                setSearchResults(results.data);
            }
        } catch (error) {
            console.error('Error occurred while searching:', error);
            setSearchResults({anime: [], user: []});
        }
    };

    // Function to clear search results
    const clearSearchResults = () => {
        setSearchResults({anime: [], user: []});
    };

    // Function to handle clicking outside of the search bar
    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            clearSearchResults();
        }
    };

    // Add event listener to handle clicking outside of the search bar
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Prevent scrolling when the HeaderMobile component is mounted
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Re-enable scrolling when the component is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuOpen]);

    // Check if sessionKey exists in local storage
    const sessionKey = localStorage.getItem('sessionKey');

    return (
        <div>
            <div className={`header-menu-bar ${!menuOpen ? 'hidden' : ''}`}>
                <div className="searchBar" ref={searchRef}>
                    <input
                        type="text"
                        placeholder="Поиск аниме"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    {searchResults.anime.length > 0 | searchResults.user.length > 0 ? (
                        <div className="searchResultsBox">
                            {searchResults.anime.map((anime) => (
                                <a className='search-result' key={anime.id} href={`/anime/${anime.id}`}>
                                    <p className='search-result-text'>{anime.name}</p>
                                    <p className={`search-rating ${anime.rating >= 7.5 ? anime.rating >= 9 ? "premium" : "good" : ""}`}>★ {anime.rating}</p>
                                </a>
                            ))}
                            {searchResults.user.map((user) => (
                                <a className='search-result' key={user.id} href={`/profile/${user.nickname}`}>
                                    <ProfilePic className="accountImage" account={user} style={{"scale": 0.2}}/>
                                    <p className='search-result-text' style={{"margin-left": 10}}>{user.nickname}</p>
                                    {/* <p style={{"opacity": 0.6, "margin": 0}}>Профиль</p> */}

                                </a>
                            ))}
                        </div>
                    ) : ""}
                </div>
                {sessionKey ? (
                    ""
                ) : (
                    <a className="loginButton" href="/login">Войти</a>
                )}
                <a href={`/leaderboard`}><h3>Лидерборд</h3></a>
                <a href={`/collection/${localStorage.getItem("profileId")}`}><h3>Моя коллекция</h3></a>
            </div>
            <header className={`headerMobile ${scrolled ? 'headerScrolled' : ''}`}>
                <a href="/">
                    <img className="logo" src={logo} alt="Logo"/>
                </a>
                <div className='rightContainer'>
                    {sessionKey ? (
                        <div>
                            <img src={NotifyIcon} alt="Play Icon" className='notify-icon' onClick={() => {
                                setShowPopupNot(!showPopupNot);
                                setShowPopup(false);
                            }}/>
                            <div className="profile-container" onClick={togglePopup}>
                                <ProfilePic className="accountImage"/>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <ProfilePopup visible={showPopup}/>
                    <NotificationPopup visible={showPopupNot} onClose={() => setShowPopupNot(false)}/>
                </div>
                <div className='menuIcon' onClick={() => setMenuOpen(!menuOpen)}>
                    <div className='menuLine'></div>
                    <div className='menuLine'></div>
                    <div className='menuLine'></div>
                </div>
            </header>
        </div>
    );
};

export default HeaderMobile;