import React, { useState } from 'react';
import './SettingsMenu.css'; // Ensure to create this CSS file
import SkipMarkForm from './SkipMarkForm';

const SettingsMenu = ({ visible, videoRef, series, debugInfo, onSpeedChanged,  }) => {
    const [quality, setQuality] = useState('1080p');
    const [speed, setSpeed] = useState(1);
    const [dubbing, setDubbing] = useState('Стандарт');
    const [state, setState] = useState("def");

    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        onSpeedChanged(newSpeed);
        setState('def');
    };


    const renderContent = () => {
        switch (state) {
            case 'def':
                return (<>
                    <div className="settings-option" onClick={() => setState('quality')}>
                        <span>Качество</span>
                        <span className="option-value">{quality}</span>
                    </div>
                    <div className="settings-option" onClick={() => setState('speed')}>
                        <span>Скорость</span>
                        <span className="option-value">{speed}x</span>
                    </div>
                    <div className="settings-option" onClick={() => setState('sound')}>
                        <span>Озвучка</span>
                        <span className="option-value">{dubbing}</span>
                    </div>
                    {localStorage.getItem("editor") === "true" || localStorage.getItem("mod") === "true"  ? <div>  <div className="settings-option debug" onClick={() => setState('marks')}>
                        <span>Редактирование меток</span>
                    </div></div> : ""}
                    <div className="settings-option debug" onClick={() => setState('debug')}>
                        <span>Для разработчиков</span>
                    </div>
                </>);
            case 'speed':
                return (<>  <div className="settings-option" onClick={() => handleSpeedChange(1)}>1x</div>
                    <div className="settings-option" onClick={() => handleSpeedChange(1.25)}>1.25x</div>
                    <div className="settings-option" onClick={() => handleSpeedChange(1.5)}>1.5x</div>
                    <div className="settings-option" onClick={() => handleSpeedChange(1.75)}>1.75x</div>
                    <div className="settings-option">

                        <p className="settings-text">Чтобы серия была засчитана, смотрите большую часть на 1x</p>
                    </div>  </>);
            case 'quality':
                return (<>   <div className="settings-option" onClick={() => setState('def')}>1080p</div> </>);
            case 'marks':
                return (<>   <div className="settings-option" onClick={() => setState('def')}>Закрыть</div>  <SkipMarkForm videoRef={videoRef} series={series}/> </>);
            case 'sound':
                return (<>
                    <div className="settings-option" onClick={() => setState('def')}>Стандарт</div>

                </>);
            case 'debug':
                return (
                    <div className="settings-option" onClick={() => setState('def')}>

                        <p className="debug-text">{JSON.stringify(debugInfo)}</p>
                    </div>);
        }
    }

    return (
        <div className={`settings-menu ${!visible && state != 'debug' ? 'hidden' : ''}`}>
            {renderContent()}
        </div>
    );
};

export default SettingsMenu;
