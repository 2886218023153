import React, { useState, useEffect } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import logo from './../assets/logo_150.svg'; // Import logo.svg from the assets folder
import Header from './../components/Header';
import Footer from '../components/Footer';

const LegalPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                    <h1>Правообладателям</h1>
                    <p>
                        Деятельность платформы "animix" осуществляется в соответствии с законодательством Российской Федерации в области защиты информации и авторских прав на контент.<br/>
                        Все размещенные на ресурсе материалы находятся в свободном доступе и могут быть бесплатно скачаны из интернета. Сбор информации в сети и размещение контента в каталоге производится в автоматическом режиме.<br/>
                        <br/>
                        Публикация нелицензионного, похищенного контента и материалов, защищенных авторским правом, не допускается. Администрация размещает только любительские русскоязычные материалы из свободных источников при использовании автоматической системы.
                        <br/><br/>
                        На платформе "animix", публикуются только фрагменты материалов, переведенные на русский язык, а также контент с любительским переводом для ознакомительного просмотра. Размещение оригинальных, непереведенных материалов невозможно.
                        <br/><br/>
                        Администрация ресурса предлагает сотрудничество с правообладателями контента. В случае нарушения прав собственности сайт обязуется убрать неправомерно размещенный материал или предложить выгодные условия сотрудничества правообладателю.
                        <br/><br/>
                        Если вы обнаружили материал, представленный на нашем сайте, который нарушает ваши авторские права, или же дискредитирует Вашу компанию, предоставляя неверную или искаженную информацию, пожалуйста свяжитесь с нами для решения этого вопроса.
                        <br/><br/>
                        Для этого необходимо отправить e-mail с вашего корпоративного почтового ящика содержащий:<br/>
                        <br/>
                        - контактные данные, реквизиты вашей компании;<br/>
                        - прямую ссылку(ссылки) на материал, который вы считаете спорным;<br/>
                        - заверенные сканированные копии документов, подтверждающих ваше эксклюзивное право на материал;<br/>
                        - в случае, если вы представляете интересы другой компании – копии документов на посреднические услуги;<br/><br/>
                        На адрес obdristaev@gmail.com<br/>
                        <br/><br/>
                        Вся информация будет проверена, и администрация сайта в кратчайшие сроки свяжется с вами для урегулирования спорного вопроса.
                    </p>
                </div>
            <Footer />
        </div>
    );
};

export default LegalPage;
