import React, { useState, useEffect, useRef } from 'react';
import './animix-premium.css';
import adFreeIcon from '../../assets/ad-free.svg';
import profileFrameIcon from '../../assets/profile-frame.svg';
import neuralRecommendationsIcon from '../../assets/neural-recommendations.svg';
import animixPremiumLogo from '../../assets/animix-premium-logo.svg'; // Assuming you have the logo SVG
import ApiService from '../../services/ApiService'; // Assuming this is the correct path to your ApiService

const AnimixPremiumGiftPopup = ({ nickname, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const popupRef = useRef(null);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubscribe = async (isMonthly) => {
    if (!validateEmail(email)) {
      setEmailError('Пожалуйста, укажите корректный адрес');
      return;
    }
    
    setEmailError('');

    try {
      const checkoutLink = await ApiService.getCheckoutGift(isMonthly, nickname, email);
      window.location.href = checkoutLink.data;
    } catch (error) {
      console.error('Error fetching checkout link:', error);
      // Optionally, display an error message to the user
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisible(false);
      if (onClose) onClose();
    }
  };

  const fetchEmail = async () => {
    try
    {
       const data = await ApiService.getEmail();
       if(data.type == "success")
        {
          setEmail(data.data);
        }
    }
    catch{}
   
  };

  useEffect(() => {
    fetchEmail();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className="prem-popup-container">
      <div className="prem-popup-content" ref={popupRef}>
        <img src={animixPremiumLogo} alt="Animix Premium" className="prem-popup-logo" />
        <h2>Подарок {nickname}</h2>
        <div className="prem-popup-feature">
          <img src={adFreeIcon} alt="Ad Free" />
          <span>У {nickname} не будет рекламы</span>
        </div>
        <div className="prem-popup-feature">
          <img src={profileFrameIcon} alt="Special Profile Frame" />
          <span>Фон будет на всю страницу, а также на комментариях и отзывах + Специальная рамка</span>
        </div>
        <div className="prem-popup-feature">
          <img src={neuralRecommendationsIcon} alt="Neural Recommendations" />
          <span>Нейронные рекомендации</span>
        </div>
        <div className="prem-popup-email">
          <input 
            type="email" 
            style={{margin: 0}}
            placeholder="Почта для чека*" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
          {emailError && <p className="email-error">{emailError}</p>}
        </div>
        <div className="prem-popup-pricing">
          <button 
            className="monthly-btn" 
            onClick={() => handleSubscribe(true)}
          >
            Подарить на месяц <span>199 Р</span>
          </button>
          <button 
            className="yearly-btn" 
            onClick={() => handleSubscribe(false)}
          >
            Подарить на год <span>1990 Р</span> <span>~-20%</span>
          </button>
        </div>
        <p className="prem-popup-note">Это единоразовый платёж Aiko, Ваша карта не будет сохранена. <br/> * - обязательное поле</p>
      </div>
    </div>
  );
};

export default AnimixPremiumGiftPopup;
