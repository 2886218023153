import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from './../assets/logo.svg';
import defaultPlaceholder from './../assets/placeholder.png';
import ApiService from './../services/ApiService';
import ErrorCodes from '../services/ErrorCodes';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterPage = () => {
    const [placeholderImage, setPlaceholderImage] = useState(defaultPlaceholder);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hasProblem, setHasProblem] = useState(false);
    const [problemText, setProblemText] = useState('');
    const navigate = useNavigate();
    const captchaRef = useRef(null);
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [captchaKey, setCapthcaKey] = useState(null);


    useEffect(() => {
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        document.title = 'Регистрация в animix';
        const randomImageNumber = getRandomNumber(1, 3);

        try {
            const randomImage = require(`./../assets/back_${randomImageNumber}.png`);
            setPlaceholderImage(randomImage);
        } catch (error) {
            console.error('Error loading random placeholder image:', error);
        }
    }, []);

    const handleCreateAccount = async (event) => {
        
        event.preventDefault();
        if (password !== confirmPassword) {
            setHasProblem(true);
            setProblemText('Пароли не совпадают');
            return;
        }

        if (password.length < 8) {
            setHasProblem(true);
            setProblemText('Пароль слишком короткий');
            return;
        }

        try {
            setHasProblem(false);
            const response = await ApiService.registerUser(login, password, 121212, captchaKey);
           
            if (response["type"] === "success") {
                localStorage.setItem('sessionKey', response.data.key);
                navigate('/');
            } else {
                setHasProblem(true);
                setProblemText(ErrorCodes.getText(response));
            }
        } catch (error) {
            console.error('Error registering user:', error.message);
            setHasProblem(true);
            setProblemText("Неизвестная ошибка. ", error.message);
        }
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const setTokenFunc = (getToken) => {
        setToken(getToken);
    };

    function onChange(value) {
  
       setCapthcaKey(value)
    }

    return (
        <div className="login-page">
            <div className="left-section">
                <div className="placeholder-container">
                    <img src={placeholderImage} alt="Placeholder" className="placeholder-image" />
                </div>
            </div>

            <div className="right-section">
                <a href="/">
                    <img className="login-logo" src={logo} alt="Logo" />
                </a>
                <form className="login-form" onSubmit={handleCreateAccount}>
                    {hasProblem && <p className="banner">{problemText}</p>}
                    <input type="text" placeholder="Логин" value={login} onChange={(e) => setLogin(e.target.value)} />
                    <input type="password" placeholder="Пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <input type="password" placeholder="Повтор" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    {/* 
                    <GoogleReCaptchaProvider reCaptchaKey="6LcnTsgpAAAAABZS9bfda3sIs6ZwKhsJxQ_t_1H-">
                        <GoogleReCaptcha
                            className="google-recaptcha-custom-class"
                            ref={captchaRef}
                            onVerify={setTokenFunc}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                    </GoogleReCaptchaProvider> */}
                    <ReCAPTCHA
                     
                        sitekey="6Lf6h9ApAAAAAODQYcRq6eceeVxjNtcOnIm-JUuU"
                        onChange={onChange}
                    />,
                    <button type="submit" className='button-login'>Создать аккаунт</button>
                    <button className='button-sec login' onClick={handleLogin}>Войти</button>
                    <p className="privacy">Используя сайт, вы соглашаетесь с нашей политикой конфиденциальности</p>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;
