import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { connect, sendMessage } from './WebSocketService';
import './WatchRoom.css'; // Import the CSS file for styling
import watchRoomIcon from '../../../assets/watchRoom.svg';
import logoutIcon from '../../../assets/ic_logout.svg';
import checkIcon from '../../../assets/ic_check.svg';
import arrowIcon from '../../../assets/ic_arrow_right.svg';
import createRoomIcon from '../../../assets/ic_create_watchroom.svg';
import joinRoomIcon from '../../../assets/ic_join_watchroom.svg';

const WatchRoom = forwardRef((params, ref) => {
    const [roomId, setRoomId] = useState('');
    const [nickname, setNickname] = useState(localStorage.getItem("nickname"));
    const [messageText, setMessageText] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [connected, setConnected] = useState(false);
    const [lastTimeSync, setLastTimeSync] = useState(Date.now());

    const chatWindowRef = useRef(null);

    const [isCopied, setIsCopied] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(roomId).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        }, () => {
            console.error("Failed to copy the text.");
        });
    };

    const handleJoinRoom = () => {
        if (roomId) {
            connect(
                nickname,
                roomId,
                message => {
                    console.log(JSON.stringify(message))
                    setChatMessages(prevMessages => [...prevMessages, message])
                },
                handleWatchTime,
                handlePlayPause,
                params.onSeriesChange,
                () => {
                    setLastTimeSync(Date.now() + 10000)
                    setConnected(true);
                }
            );
        }
    };

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours.toString().padStart(2, '0')}:`;
        }

        formattedTime += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return formattedTime;
    };

    const syncTime = (newTime) => {
        const messageNot = { content: "", sender: nickname + " перематывает на " + formatTime(newTime) };
        sendMessage(`/app/chat/${roomId}`, messageNot);
        const message = { content: newTime, sender: nickname };
        sendMessage(`/app/watchTime/${roomId}`, message);
    };

    const togglePlayPause = (play) => {
        let info = play == true ? " начал просмотр" : " поставил на паузу";
        const messageNot = { content: "", sender: nickname + info };
        sendMessage(`/app/chat/${roomId}`, messageNot);
        const message = { content: play, sender: nickname };
        sendMessage(`/app/playPause/${roomId}`, message);
    };

    const handleExpanded = () => {
        setIsExpanded(!isExpanded);
    }

    const handlePlayPause = (message) => {
        console.log(JSON.stringify(message))
        if (message.sender !== nickname) {
            if (message.content === true) {
                params.videoRef.current.play();
            } else {
                params.videoRef.current.pause();
            }
        }
    }

    const handleWatchTime = (message) => {
        console.log(JSON.stringify(message))
        if (message.sender !== nickname) {
            params.videoRef.current.currentTime = Number.parseInt(message.content);
        }
    }

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    }
    
    const handleNewRoom = () => {
        var roomId = "animixinvite" + generateRandomString(20);
        setRoomId(roomId);

        connect(
            nickname,
            roomId,
            message => {
                console.log(JSON.stringify(message))
                setChatMessages(prevMessages => [...prevMessages, message])
            },
            handleWatchTime,
            handlePlayPause,
            params.onSeriesChange,
            () => { setConnected(true); }
        );
    };

    const handleSendMessage = () => {
        if (messageText.trim()) {
            const message = { content: messageText, sender: nickname };
            sendMessage(`/app/chat/${roomId}`, message);
            setMessageText(''); // Clear the input field after sending the message
        }
    };

    const handleLogout = () => {
        setConnected(false);
        setRoomId('');
        setChatMessages([]);
        setIsExpanded(false);
        // Disconnect from WebSocket if needed
    };

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [chatMessages]);

    useImperativeHandle(ref, () => ({
        syncTime, togglePlayPause
    }));

    return (
        <div>
            {isExpanded ? (
                <div className={`watch-room ${params.isFullScreen ? "fullscreen" : ""}  ${params.isHidden ? "hidden" : ""}`}>
                    <img onClick={handleLogout} src={logoutIcon} className={`logout-button  ${params.isHidden ? "hidden" : ""}`} alt="Выйти" />
                    {!connected ? (
                        <div className={`room-settings ${params.isHidden ? "hidden" : ""}`}>
                            <h3>Совместный просмотр</h3>
                            <div className="cards">
                                <button className='button-sec watch-room-button' onClick={handleNewRoom}>
                                    <img src={createRoomIcon} className="watch-room-icon" alt="Создать комнату" />
                                    <span>Создать комнату</span>
                                    <p>Создать лобби для просмотра и пригласить людей</p>
                                </button>
                                <button className='watch-room-button' >
                                    <img src={joinRoomIcon} className="watch-room-icon" alt="Создать комнату" />
                                    <span>Вступить в комнату</span>
                                    <p>Введите код приглашения</p>
                                    <div className='watch-code-input-con'>
                                   
                                        <input
                                            type="text"
                                            placeholder='Введите ключ комнаты'
                                            value={roomId}
                                            onChange={e => setRoomId(e.target.value)}
                                        />
                                        <div>
                                             <img src={checkIcon} onClick={handleJoinRoom} className="check-icon" alt="Создать комнату" />
                                        </div>
                                    </div>
                                   
                                
                                </button>
                               
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="chat-window">
                                <h3>Чат комнаты (beta)</h3>
                                <p onClick={handleCopy} className='room-key'>
                                    {isCopied ? "Приглашение скопировано!" : "Пригласить"}
                                </p>
                                <ul ref={chatWindowRef}>
                                    {chatMessages.map((msg, index) => (
                                        <li key={index}>
                                            <a className='watch-room-sender'>{msg.sender}  </a>
                                            <a className='chat-message-text'>{msg.content}</a>
                                        </li>
                                    ))}
                                </ul>
                                <p className='hint'>Enter для отправки</p>
                                <input
                                    value={messageText}
                                    placeholder="Напишите сообщение"
                                    onChange={e => setMessageText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSendMessage();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <img
                    onClick={handleExpanded}
                    className={`img-button-watchroom ${params.isFullScreen ? "fullscreen" : ""}  ${params.isHidden ? "hidden" : ""}`}
                    src={watchRoomIcon}
                />
            )}
        </div>
    );
});

export default WatchRoom;
