import React, {useEffect, useRef, useState} from 'react';
import ErrorCodes from '../../services/ErrorCodes';

const ChangePassPopup = ({ handleChangePass, closePopup, error }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);

    const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

    const handleSubmit = () => {
        if (newPassword === confirmPassword) {
            handleChangePass(newPassword);
        } else {
            setPasswordError('Пароли не совпадают');
        }
    };

    const [isVisible, setIsVisible] = useState(true);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsVisible(false);
            if (closePopup) closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <div className="popup-container">
            <div className="popup" ref={popupRef}>
                <h2>Сменить пароль</h2>
                <div>
                    <p className='settings-text'>Новый пароль</p>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        className="settings-input"
                        placeholder="Введите новый пароль"
                    />
                    <p className='settings-text'>Повторите пароль</p>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="settings-input"
                        placeholder="Введите новый пароль ещё раз"
                    />
                </div>
                {error && <p className="banner">{ErrorCodes.getText(error)}</p>}
                {passwordError && <p className="banner">{passwordError}</p>}
                <div>
                    <button className='button-sec' onClick={closePopup}>Отмена</button>
                    <button onClick={handleSubmit}>Сменить</button>
                </div>
            </div>
        </div>
    );
};

export default ChangePassPopup;