import React, { useState, useRef, useEffect } from 'react';
import './VideoPlayer.css';

import playIcon from '../../assets/ic_play_white.svg';
import pauseIcon from '../../assets/ic_pause.svg';
import lowVolumeIcon from '../../assets/ic_low_volume.svg';
import midVolumeIcon from '../../assets/ic_mid_volume.svg';
import mutedVolumeIcon from '../../assets/ic_muted_volume.svg';
import volumeIcon from '../../assets/ic_volume.svg';
import fullscreenIcon from '../../assets/ic_fullscreen.svg';
import settingsIcon from '../../assets/ic_settings_micro.svg';
import jimmy from '../../assets/jimmy.svg';
import WatchRoom from './socket/WatchRoom';
import { useDetectAdBlock } from "adblock-detect-react";
import CountdownOverlay from './CountdownOverlay';
import SettingsMenu from './SettingsMenu';
import SkipMarkForm from './SkipMarkForm';
import ApiService from '../../services/ApiService';

const VideoPlayer = ({ src, series, updateWatchTime, timeSec, timeSet, setTimeSet, onVideoFinished, onSeriesChange, adDetected }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentSkipmark, setCurrentSkipmark] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [hasBigPlayButton, setHasBigPlayButton] = useState(false);
    const [volumeLevel, setVolumeLevel] = useState(localStorage.getItem("volume"));
    const [sliderValue, setSliderValue] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [error, setError] = useState(null);
    const [adblock, setAdblock] = useState(false);
    const [settingShown, setSettingsShown] = useState(false);

    const [skipTimeout, setSkipTimeout] = useState(null);

    const [countdown, setCountdown] = useState(0);
    const [autoRetrycount, setAutoretry] = useState(0);
    const [isCountdownActive, setIsCountdownActive] = useState(false);
    const [isCountdownCancelled, setIsCountdownCancelled] = useState(false);

    const [isSeeking, setIsSeeking] = useState(false);

    const videoRef = useRef(null);
    const watchRoomRef = useRef(null);

    const videoContainerRef = useRef(null);

    const [skipMarks, setSkipMarks] = useState([]);
    const adBlockDetected = useDetectAdBlock();

    const [tooltip, setTooltip] = useState({ label: "", visible: false, x: 0 });

    let timeoutId;

    const fetchSkipMarks = async () => {
        try {
            const response = await ApiService.getSkipMarks(series.animeId, series.seriesNum, series.season);
            setSkipMarks(response.data);
        } catch (error) {
            console.error('Error fetching skip marks:', error);
        }
    };

    const togglePlayPause = () => {
        var isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended
            && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
            watchRoomRef.current.togglePlayPause(false);
        } else {
            try {

                videoRef.current.play();
                watchRoomRef.current.togglePlayPause(true);
                setIsPlaying(true);
            }
            catch (e) {
                setIsPlaying(false);
            }

        }
    };



    const handleFullScreenChange = () => {
        setIsFullScreen(document.fullscreenElement !== null);
    };

    useEffect(() => {

        // if (adDetected) {
        //     if (localStorage.getItem("subscribed") !== "true") {
        //         setAdblock(true);
        //         setIsPlaying(false);
        //         videoRef.current.pause();
        //         videoRef.current.src = "";
        //         setError({ custom: true, message: "Пожалуйста, выключите блокировщик рекламы или подключите подписку. Рекламы в плеере нет.", title: "Котёнку нечего есть" })
        //     }
        // }

        return () => {

        };
    }, [adDetected]);




    useEffect(() => {
        fetchSkipMarks();
    }, [src]);

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreenChange);





        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, [currentTime, isPlaying]);

    useEffect(() => {
        // Pass current time to parent component
        updateWatchTime(currentTime);

    }, [currentTime, updateWatchTime]);

    const toggleMute = () => {
        if (isMuted) {
            videoRef.current.muted = false;
            setVolumeLevel(1);
        } else {
            videoRef.current.muted = true;
            setVolumeLevel(0);
        }
        setIsMuted(!isMuted);
    };

    const handleTimeUpdate = () => {
        const newTime = videoRef.current.currentTime;
        setCurrentTime(newTime);
        setSliderValue(newTime);


        const video = videoRef.current;

        const currentTime = video.currentTime;
        const duration = video.duration;
        const percentage = currentTime / duration;






      
    };





    useEffect(() => {
        const checkForSkipmark = () => {
            const currentTime = videoRef.current.currentTime;
            let foundSkipmark = null;

            for (let skipMark of skipMarks) {
                if (currentTime >= skipMark.startTime && currentTime <= skipMark.finishTime) {
                    foundSkipmark = skipMark;

                    if(foundSkipmark.label === "Эндинг"){
                        if (!isCountdownActive && countdown == 0) {
                            setCountdown(5);
                            setIsCountdownActive(true); // Start the countdown and mark it as active
                        }
                    }
                    break;
                }
            }

            setCurrentSkipmark(foundSkipmark);
        };

        const interval = setInterval(checkForSkipmark, 500); // Check every 500ms

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, [skipMarks, videoRef, countdown, isCountdownActive]);

    useEffect(() => {
        if (currentSkipmark) {
            if (currentSkipmark.autoSkip) {
                const timeout = setTimeout(() => {

                    videoRef.current.currentTime = currentSkipmark.finishTime;
                }, 6000);

                setSkipTimeout(timeout);

                // Cleanup the timeout if the component unmounts or if currentSkipmark changes
                return () => clearTimeout(timeout);
            }

        }
    }, [currentSkipmark, videoRef]);

    const handleClick = () => {
        // Cancel the skip when the button is clicked
        if (skipTimeout) {
            clearTimeout(skipTimeout);
            setSkipTimeout(null);
        }
    };


    const handleSeek = (e) => {
        let newTime = e.target.value;
        if (newTime < 1) newTime = 1;
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
        setSliderValue(newTime);
        clearTimeout(skipTimeout);
        setSkipTimeout(null);
        if (!isSeeking) {
            
            watchRoomRef.current.syncTime(newTime);
        }
    };



    const handleMouseDown = () => {
        setIsSeeking(true);
    };

    const handleMouseUp = () => {
        setIsSeeking(false);
        watchRoomRef.current.syncTime(sliderValue);
    };

    const handleCanPlay = () => {
        if (adblock) return;
      
        setIsLoading(false);
        setError(null);
        setAutoretry(0);
        setIsCountdownActive(false)
        setIsCountdownCancelled(false)
        setCountdown(0)
        if (localStorage.getItem("volume") != null) {
            setVolumeLevel(localStorage.getItem("volume"));
            videoRef.current.volume = localStorage.getItem("volume");
        }
        else {
            setVolumeLevel(1);
            videoRef.current.volume = 1;
        }

        const isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended
            && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        if (!isPlaying) {
            try {

                videoRef.current.play().catch(error => {
                    // Autoplay was prevented.
                    setIsPlaying(false);
                    setHasBigPlayButton(true);
                });
                setIsPlaying(true);
                watchRoomRef.current.syncTime(videoRef.current.currentTime);

            } catch (e) {
                setIsPlaying(false);
            }
        }
        if (videoRef.current != null) {
            if (!timeSet) {
                videoRef.current.currentTime = timeSec;
                setTimeSet(true);
            }

        }
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        videoRef.current.volume = newVolume;
        setVolumeLevel(newVolume);
        localStorage.setItem("volume", newVolume)
        setIsMuted(newVolume === 0);
    };

    const toggleSettings = () => {
        setSettingsShown(!settingShown);
    }

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (videoContainerRef.current.requestFullscreen) {
                videoContainerRef.current.requestFullscreen();
            } else if (videoContainerRef.current.mozRequestFullScreen) {
                videoContainerRef.current.mozRequestFullScreen();
            } else if (videoContainerRef.current.webkitRequestFullscreen) {
                videoContainerRef.current.webkitRequestFullscreen();
            } else if (videoContainerRef.current.msRequestFullscreen) {
                videoContainerRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }

    };

    const handleMouseMove = (e) => {
        setShowControls(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setShowControls(false);
        }, 3000);

        const sliderEl = document.querySelector("#range");
        const rect = sliderEl.getBoundingClientRect();
        try {
            const posX = e.clientX - rect.left;

            const percentage = (posX / rect.width) * 100;
            const time = (percentage / 100) * videoRef.current.duration;

            for (let skipMark of skipMarks) {
                if (time >= skipMark.startTime && time <= skipMark.finishTime && e.clientY - rect.top > -20 && e.clientY - rect.top < 30) {
                    setTooltip({ label: skipMark.label, visible: true, x: posX });
                 
                    return;
                }
            }
            setTooltip({ label: "", visible: false, x: 0 });
        }
        catch { }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const handleError = async (e) => {


        setAutoretry(autoRetrycount + 1);

        if (autoRetrycount < 7) {
            if (autoRetrycount > 2) {
                console.log("retrying " + autoRetrycount);
                await sleep(5000);
            }
            try {

                videoRef.current.load();
            }
            catch {
                if (error == null) {
                    setError(e);
                }
            }
            return;
        }
        if (error == null) {
            setError(e);
        }
    };

    const handleLoading = () => {
        setIsLoading(true);
        setError(null);
    };

    const getErrorMessage = (error) => {
        let errorInfo = '';
        try {
            const errorCode = error.target.error.code;


            switch (errorCode) {
                case 1:
                    errorInfo = 'При обработке видео возникла проблема (corrupted source)';
                    break;
                case 2:
                    errorInfo = 'Ошибка сети. Проверьте интернет-соединение';
                    break;
                case 3:
                    errorInfo = 'Ошибка декодирования видео';
                    break;
                case 4:
                    errorInfo = 'Возможно, Вы отправляете слишком много запросов. Подождите немного и обновите страницу.';
                    break;
                default:
                    errorInfo = 'Неизвестный код ошибки: ' + errorCode;
                    break;
            }
        } catch (e) {
            errorInfo = 'Неизвестная ошибка ';
            if (error.custom != null) {
                return error.message;
            }
        }


        return errorInfo;
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            const activeElement = document.activeElement;
            const isInputFocused = activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA';

            if (!isInputFocused) {

                switch (event.key) {
                    case 'ArrowRight':
                        videoRef.current.currentTime += 5;
                        break;
                    case 'ArrowLeft':
                        videoRef.current.currentTime -= 5;
                        break;
                    case ' ':
                        togglePlayPause();
                        event.preventDefault();
                        break;
                    default:

                        break;
                }
            }
            handleMouseMove();
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [skipMarks]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [skipMarks]);
    
    useEffect(() => {
        const sliderEl = document.querySelector("#range");
        const progress = (sliderValue / videoRef.current.duration) * 100;
        const gradients = skipMarks.map(skipMark => {
            const startPercent = (skipMark.startTime / videoRef.current.duration) * 100;
            const finishPercent = (skipMark.finishTime / videoRef.current.duration) * 100;
            return `linear-gradient(to right, #ffffff00 ${startPercent}%,  #757575   ${startPercent}%, #757575  ${finishPercent}%, #ffffff00   ${finishPercent}%)`;
        });
        if (skipMarks.length == 0) {
            sliderEl.style.background = `linear-gradient(to right, #7544B2 ${progress}%, #ffffff4d ${progress}%)`;
        }
        else {
            sliderEl.style.background = `linear-gradient(to right, #7544B2 ${progress}%, #ffffff4d ${progress}%), ${gradients.join(', ')}`;
        }


    }, [sliderValue]);

    useEffect(() => {
        setIsLoading(true);
    }, [src]);

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours.toString().padStart(2, '0')}:`;
        }

        formattedTime += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return formattedTime;
    };
    useEffect(() => {
        let lastTap = 0;
        const videoElement = videoRef.current;

        const handleTouchStart = (e) => {
            const currentTime = videoElement.currentTime;
            const timeJump = 10; // Time to jump in seconds

            const now = new Date().getTime();
            const timesince = now - lastTap;
            if (timesince < 600 && timesince > 0) {
                const tapX = e.touches[0].clientX;
                const halfWidth = window.innerWidth / 2;

                if (tapX > halfWidth) {
                    // Double tap on the right side, skip forward
                    videoElement.currentTime = currentTime + timeJump;
                } else {
                    // Double tap on the left side, skip backward
                    videoElement.currentTime = currentTime - timeJump;
                }
            } else {
                // Single tap
            }
            lastTap = now;
        };

        videoElement.addEventListener('touchstart', handleTouchStart);

        return () => {
            videoElement.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);

    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent default right-click menu
    };
    const handlePlayButton = () => {
        setHasBigPlayButton(false)
        togglePlayPause();
    };

    const handleCancelCountdown = () => {
        setCountdown(0); // Reset the countdown
        setIsCountdownActive(false); // Mark the countdown as inactive
        setIsCountdownCancelled(true)
    };

    const handleSpeedChange = (speed) => {
        videoRef.current.playbackRate = speed;
    };

    useEffect(() => {
        if (countdown > 0) {

            const countdownTimeout = setTimeout(() => {

                if (!isCountdownCancelled) {
                    setCountdown(countdown - 1);
                    if (countdown - 1 == 0 && !isCountdownCancelled) {

                        onVideoFinished();
                        setIsCountdownActive(false);
                    }
                }
            }, 1000);

            return () => clearTimeout(countdownTimeout);
        }
    }, [countdown, isCountdownCancelled]);


    return (
        <div
            ref={videoContainerRef}
            onContextMenu={handleContextMenu}
            className={`video-container ${isLoading ? 'loading' : ''} ${isFullScreen ? 'fullscreen' : ''}`}
        >

            {isLoading && <div className='fullscreeen-centered'><div className="loader">  </div></div>}
            {hasBigPlayButton && <div className='fullscreeen-centered' onClick={handlePlayButton}><img className='big-play-button' src={playIcon} onClick={handlePlayButton} /></div>}
            {error != null && <div className='fullscreeen-centered'><div><img src={jimmy} /> <h2>{error.custom == null ? "Произошла ошибка" : error.title}</h2> <p>{getErrorMessage(error)}</p></div></div>}
            <video
                className={showControls ? "video-player" : "video-player-theatre"}
                ref={videoRef}
                currentTime={timeSec}
                type='video/mp4'
                src={adblock ? "" : src}
                volume={localStorage.getItem("volume")}
                onClick={() => {
                    if (settingShown) {
                        setSettingsShown(false);
                        return;
                    }

                    togglePlayPause();
                }}
                onEnded={() => { setTimeSet(false); }}
                onLoad={videoRef.current != null ? (videoRef.current.currentTime < 1) ? videoRef.current.currentTime = timeSec : "" : ""}
                onTimeUpdate={handleTimeUpdate}
                onCanPlay={handleCanPlay}
                onWaiting={handleLoading}
             
                onError={handleError}
                playsinline
            />
            <div className={showControls ? "controls" : "controls-hide"}>

                <button className='controls-button' onClick={togglePlayPause}>
                    <img className='img-button' src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? 'Pause' : 'Play'} />
                </button>
                <button className='controls-button'>
                    <img className='img-button' src={isMuted ? mutedVolumeIcon : volumeIcon} alt={isMuted ? 'Unmute' : 'Mute'} />
                    <div className="volume-controls">
                        <input
                            type="range"
                            min={0}
                            max={1}
                            step={0.01}
                            value={volumeLevel}
                            onChange={handleVolumeChange}
                            className="controls-input vertical"
                        />
                    </div>
                </button>
                <p className="time-text">{formatTime(currentTime)}</p>
                <input
                    type="range"
                    id="range"
                    min="0"
                    max={videoRef.current && videoRef.current.duration}
                    value={sliderValue}
                    onChange={handleSeek}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    className={isFullScreen ? "controls-input fullscreen" : "controls-input"}
                />

                <div className={`tooltip-video ${!tooltip.visible | !showControls ? "hide" : ""}`}>
                    {tooltip.label} </div>

                <p className="time-text">{videoRef.current ? videoRef.current.duration > 0 ? formatTime(videoRef.current ? videoRef.current.duration : 0) : "0:00" : "0:00"}</p>
                <button className='controls-button' onClick={toggleSettings}>

                    <img className='img-button' src={settingsIcon} alt="Fullscreen" />
                </button>
                <button className='controls-button' onClick={toggleFullScreen}>

                    <img className='img-button' src={fullscreenIcon} alt="Fullscreen" />
                </button>


            </div>
            {currentSkipmark != null ? (
                (currentSkipmark.autoSkip && skipTimeout != null | !currentSkipmark.autoSkip) ?
                    <div className={`skip-button ${skipTimeout ? 'filling' : ''}`} onClick={() => {
                        if (!currentSkipmark.autoSkip) {
                            videoRef.current.currentTime = currentSkipmark.finishTime;
                        }
                        handleClick();
                    }}>
                        {currentSkipmark.autoSkip ? "Смотреть" : "Пропустить"} {currentSkipmark.label}

                    </div> : null
            ) : null}

            <CountdownOverlay
                text={"Следующая серия начнётся"}
                isCountdownActive={isCountdownActive}
                countdown={countdown}
                setCountdown={setCountdown}
                onCancel={() => {
                    setIsCountdownCancelled(true);
                    setIsCountdownActive(false);
                }}
            />

            <SettingsMenu videoRef={videoRef} series={series} visible={settingShown && showControls} onSpeedChanged={handleSpeedChange} debugInfo={{ src, currentTime, isFullScreen, error, isLoading, volumeLevel, adDetected }} />

            <WatchRoom ref={watchRoomRef} isFullScreen={isFullScreen} isHidden={!showControls} currentTime={currentTime} videoRef={videoRef} onSeriesChange={onSeriesChange} />

        </div>
    );
};

export default VideoPlayer;
